@charset "UTF-8";

/*
Tkaff theme
*/

/* Typography and Reset */

* {
  margin: 0px;
  outline: none;
  padding: 0px;
  text-decoration: none;
}

body {
  background: #fff !important;
  color: #999;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.wrapper.wrapper-boxed .container {
  padding: 0 20px;
}

.wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.wrapper.wrapper-boxed {
  margin: 0 auto 40px;
  max-width: 1250px;
  position: relative;
  background: #fff;
  -webkit-box-shadow: inset 25px 0px 25px -25px rgba(0, 0, 0, 0.2),
    inset -25px 0px 25px -25px rgba(0, 0, 0, 0.2);
  box-shadow: inset 25px 0px 25px -25px rgba(0, 0, 0, 0.2),
    inset -25px 0px 25px -25px rgba(0, 0, 0, 0.2);
}

video {
  max-width: 100%;
  height: auto;
}

iframe,
embed,
object {
  max-width: 100%;
}

/* Heading Typo */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2f313a;
  letter-spacing: 0;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700;
  margin: 0 0 10px 0;
}

h1 {
  font-size: 30px;
  line-height: 36px;
}

h2 {
  font-size: 24px;
  line-height: 30px;
}

h3 {
  font-size: 20px;
  line-height: 24px;
}

h4 {
  font-size: 18px;
  line-height: 22px;
}

h5 {
  font-size: 16px;
  line-height: 20px;
}

h6 {
  font-size: 14px;
  line-height: 18px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: #333;
  text-decoration: none;
  outline: none;
}

/* Heading Typo End */

[class*="col-lg"],
[class*="col-md"],
[class*="col-sm"] {
  display: inline-block;
  float: none;
  margin: 0 -4px 0 0;
  vertical-align: top;
}

a {
  text-decoration: none;
  color: #333;
  outline: none;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:focus {
  color: #000;
  outline: none;
  text-decoration: none;
}

p {
  line-height: 24px;
  margin: 0 0 10px;
}

figure {
  margin-bottom: 0;
  position: relative;
}

/* Blockquote typo */

blockquote,
q {
  quotes: none;
}

blockquote {
  padding: 0 20px;
  border-left: 3px solid #ff0c00;
  color: #ff0c00;
  font-size: 17px;
  font-style: italic;
  font-weight: 600;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

/* Input Style */

select {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #999;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  padding: 10px 15px;
  width: 100%;
  border: 1px solid #e4e4e4;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

textarea,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"] {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #555;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  width: 100%;
  border: 1px solid #d7d7d7;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

textarea {
  height: 175px;
}

/* Field Holder Input Style */

.field-holder #ui-datepicker-div {
  top: 100% !important;
  left: 0 !important;
}

.field-holder select {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #999;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 10px 15px;
  width: 100%;
  border: 1px solid #e4e4e4;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.field-holder textarea,
.field-holder input.field-input[type="date"],
.field-holder input[type="datetime"],
.field-holder input[type="datetime-local"],
.field-holder input[type="email"],
.field-holder input[type="month"],
.field-holder input[type="number"],
.field-holder input[type="password"],
.field-holder input[type="range"],
.field-holder input[type="search"],
.field-holder input[type="tel"],
.field-holder input[type="text"],
.field-holder input[type="time"],
.field-holder input[type="url"],
.field-holder input[type="week"] {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  color: #555;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  width: 100%;
  border: 1px solid #d7d7d7;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.field-holder textarea {
  height: 175px;
}

.main-search.fancy .field-holder .select-location input[type="text"]::-ms-clear,
input[type="search"]::-ms-clear,
input[type="text"]::-ms-clear {
  display: none;
}

/* Field Input Size Style */

.field-holder {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  margin-right: -4px;
}

.field-lg,
.field-md,
.field-sm,
.field-xs {
  display: inline-block;
  vertical-align: top;
  margin-right: -4px;
}

.field-lg {
  width: 100%;
}

.field-md {
  width: 50%;
}

.field-sm {
  width: 33.33%;
}

.field-xs {
  width: 25%;
}

/* Input Focus Style */

.field-holder input:focus {
  outline: 0;
}

.field-holder input:focus::-webkit-input-placeholder {
  color: transparent;
}

.field-holder input:focus:-moz-placeholder {
  color: transparent;
}

.field-holder input:focus::-moz-placeholder {
  color: transparent;
}

.field-holder input:focus:-ms-input-placeholder {
  color: transparent;
}

.field-holder textarea:focus {
  outline: 0;
}

.field-holder textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.field-holder textarea:focus:-moz-placeholder {
  color: transparent;
}

.field-holder textarea:focus::-moz-placeholder {
  color: transparent;
}

.field-holder textarea:focus:-ms-input-placeholder {
  color: transparent;
}

/* Table typo style */

table {
  background-color: transparent;
  margin-bottom: 15px;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  text-align: left;
}

table > tbody + tbody {
  border-top: 2px solid #d2d2d2;
}

table > thead > tr > th {
  border-bottom: 2px solid #d2d2d2;
  vertical-align: bottom;
}

table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  border-top: 1px solid #d2d2d2;
  border: 1px solid #d2d2d2;
  line-height: 2.5;
  padding-left: 7px;
  vertical-align: top;
  text-align: center;
  padding: 12px 16px;
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 1px solid #d2d2d2;
}

table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
  border-bottom: 0;
}

/* Table typo style End */

/* Rich Editor Style */

.rich_editor_text h1,
.rich_editor_text h2,
.rich_editor_text h3,
.rich_editor_text h4,
.rich_editor_text h5,
.rich_editor_text h6 {
  margin-bottom: 10px;
}

.rich_editor_text p,
.rich_editor_text ul,
.rich_editor_text ol {
  margin-bottom: 10px;
}

.rich_editor_text ul {
  float: none;
  list-style: disc;
  margin-left: 0px;
}

.rich_editor_text ol {
  list-style-type: decimal;
  margin-left: 0px;
  padding-left: 25px;
}

.rich_editor_text ul li ol li {
  padding-left: 5px;
}

.rich_editor_text ul li ol li:before,
.rich_editor_text li {
  float: none;
}

.rich_editor_text ul ul {
  list-style-type: circle;
  margin-bottom: 0;
  padding: 0 0 0 25px;
}

/* Rich Editor Style */

/* Theme Unit Style */

.alignnone {
  height: auto;
  margin-bottom: 30px;
}

.alignnone img {
  height: auto;
  margin-bottom: 30px;
}

img.alignright {
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  float: right;
  margin-left: 20px;
}

img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}

img.aligncenter {
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  display: block;
  margin: 0 auto 30px;
}

.cs-blog-unit .blog-detail .main-post img,
.cs-blog-unit .blog-medium .blog-post figure img {
  width: auto;
}

.cs-blog-unit .blog-medium .img-holder {
  background-color: transparent;
  padding: 0;
  float: right;
  margin: 0 0 0 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: auto;
  text-align: right;
}

.aligncenter img {
  display: inline-block;
}

.aligncenter {
  text-align: center;
  margin: 0 auto 30px;
}

.alignleft {
  float: left;
  margin-right: 30px;
  margin-bottom: 30px;
}

.alignright {
  float: right;
}

.element-title.align-center {
  text-align: center;
}

.element-title.align-left {
  text-align: left;
}

.element-title.align-right {
  text-align: right;
}

/* Ul Ol Li typo */

li {
  line-height: 28px;
  list-style: disc inside none;
}

ul ul {
  margin-left: 20px;
}

ol {
  float: none;
  list-style: decimal;
  padding-left: 15px;
}

ol ol {
  margin-left: 20px;
}

ol li {
  list-style: decimal;
  width: 100%;
}

ul ol {
  margin-bottom: 20px;
}

ul ol ol {
  margin-bottom: 0;
}

/* Ul Ol Li typo End */

p ins {
  color: #999;
}

dl dd {
  margin-left: 20px;
}

video {
  max-width: 100%;
  height: auto;
  width: 100%;
}

embed,
object,
iframe {
  max-width: 100%;
  width: 100%;
  border: 0;
  display: block;
}

.modal {
  overflow-y: auto;
  z-index: 10000;
}

.modal.show {
  opacity: 1;
}

/* Sticky Header */

/* Responsive Menu */

.slicknav_menu {
  display: none;
}

.main-section.page-margin {
  margin: 60px 0;
}

/* bg Slider and Video Menu Start*/

.has-bg-custom-slider,
.has-bg-custom-video {
  position: relative;
}

.has-bg-custom-slider .custom-slider-holder,
.has-bg-custom-video .custom-video-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* bg Slider and Video Menu End*/

/* Theme Custom Setup */

.page-links {
  float: left;
  padding: 0px 0px 20px 0px;
  text-align: center;
  text-transform: uppercase;
}

.page-links span {
  background: #222;
  color: #fff;
  font-size: 11px;
  padding: 5px 10px;
}

.page-links a span {
  color: #fff;
  font-size: 11px;
  padding: 5px 10px;
}

.page-links span.page-links-title {
  color: #fff;
}

.password_protected > form {
  display: inline-block;
  width: 400px;
}

.password_protected input[type="password"] {
  height: 45px;
  padding: 0 15px;
  width: 100%;
}

.password_protected {
  float: left;
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
}

.password_protected p {
  float: left;
  margin: 0;
  width: auto;
}

.password_protected label {
  float: left;
  width: 80%;
  border: 1px solid #ccc;
  margin-right: -4px;
}

.password_protected input[type="submit"] {
  background-color: #39b44d;
  border: none;
  color: #fff;
  padding: 11px 16px 12px 17px;
}

.password_protected .protected-icon a {
  background-color: #39b44d;
  border-radius: 100%;
  color: #ffffff;
  display: inline-block;
  font-size: 50px;
  height: 80px;
  line-height: 80px;
  margin: 0px 0px 20px 0px;
  padding: 3px 0px 0px 0px;
  width: 80px;
}

.password_protected .protected-icon a i {
  font-size: 42px;
}

.password_protected h3 {
  margin-bottom: 20px;
  font-size: 14px !important;
  font-weight: normal !important;
  width: 60%;
  display: inline-block;
}

img.alignnone,
.alignnone img,
.wp-caption.alignnone {
  width: 100% !important;
}

.wp-caption.alignright img {
  margin: 0 0 10px 0;
}

.wp-caption.alignright {
  margin: 0 0 10px 30px;
}

.wp-caption.aligncenter img {
  margin: 0 0 10px 0;
}

.cs-blog-unit .comments .thumb-list ul li,
.cs-blog-unit .comments .thumb-list ol li {
  list-style: disc inside;
}

.cs-blog-unit .comments ul li ul.children li {
  margin-right: 30px;
}

.cs-blog-unit .comments ul li ul.children {
  padding-bottom: 10px;
}

.cs-blog-unit .comments .thumb-list ul ul {
  margin-left: 20px;
}

/* Default Gallery Setup */

.gallery {
  clear: both;
  overflow: hidden;
}

.gallery .gallery-item {
  float: left;
  list-style: none;
  margin: 0px 30px 0;
  overflow: hidden;
  padding: 0px 0px 15px 15px;
  text-align: center;
}

.detail-content-holder .gallery .gallery-item {
  margin: 0px 30px 0;
  padding: 0px 0px 15px 15px;
  float: none;
}

.gallery-caption {
  font-size: 11px;
}

.gallery-item img {
  float: left;
  height: auto;
  padding: 0 0px;
  width: 100%;
}

.gallery-columns-0 .gallery-item {
  width: 100%;
}

.gallery-columns-1 .gallery-item {
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  width: 50%;
}

.gallery-columns-3 .gallery-item {
  width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  width: 25%;
}

.gallery-columns-5 .gallery-item {
  width: 20%;
}

.gallery-columns-6 .gallery-item {
  width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  width: 11.11%;
}

.gallery-columns-10 .gallery-item {
  width: 10%;
}

.gallery-columns-11 .gallery-item {
  width: 9.09%;
}

.gallery-columns-12 .gallery-item {
  width: 8.33%;
}

.gallery-columns-13 .gallery-item {
  width: 7.69%;
}

.gallery-columns-14 .gallery-item {
  width: 7.14%;
}

.gallery-columns-15 .gallery-item {
  width: 6.66%;
}

.gallery-columns-16 .gallery-item {
  width: 6.25%;
}

.gallery-columns-17 .gallery-item {
  width: 5.88%;
}

.gallery-columns-18 .gallery-item {
  width: 5.55%;
}

.gallery-columns-19 .gallery-item {
  width: 5.26%;
}

.gallery-columns-20 .gallery-item {
  width: 5%;
}

.gallery-columns-21 .gallery-item {
  width: 4.76%;
}

.gallery-columns-22 .gallery-item {
  width: 4.54%;
}

.gallery-columns-23 .gallery-item {
  width: 4.34%;
}

.gallery-columns-24 .gallery-item {
  width: 4.16%;
}

.gallery-columns-25 .gallery-item {
  width: 4%;
}

.gallery-columns-26 .gallery-item {
  width: 3.84%;
}

.gallery-columns-27 .gallery-item {
  width: 3.7%;
}

.gallery-columns-28 .gallery-item {
  width: 3.57%;
}

.gallery-columns-29 .gallery-item {
  width: 3.44%;
}

.gallery-columns-30 .gallery-item {
  width: 3.33%;
}

/* Default Gallery Setup */

/* Social Media Links Bg Clolor */

a[data-original-title="Facebook"] {
  background: #4267b2;
}

a[data-original-title="LinkedIn"] {
  background: #007bb6;
}

a[data-original-title="Twitter"] {
  background: #00aced;
}

a[data-original-title="Google+"] {
  background: #b63a2b;
}

a[data-original-title="YouTube"] {
  background: #bb0000;
}

a[data-original-title="Flickr"] {
  background: #ff0084;
}

a[data-original-title="RSS"] {
  background: #fa9b39;
}

a[data-original-title="Pinterest"] {
  background: #cb2027;
}

a[data-original-title="Instagram"] {
  background: #3f5c9a;
}

a[data-original-title="Vimeo"] {
  background: #0087cb;
}

a[data-original-title="Dribble"] {
  background: #ea4c89;
}

a[data-original-title="Behance"] {
  background: #1759ff;
}

a[data-original-title="Tumbler"] {
  background: #32506d;
}

a[data-original-title="Stumbleupon"] {
  background: #eb4823;
}

a[data-original-title="facebook"] {
  background: #3b558b;
}

a[data-original-title="linkedIn"] {
  background: #007bb6;
}

a[data-original-title="twitter"] {
  background: #00aced;
}

a[data-original-title="google+"] {
  background: #b63a2b;
}

a[data-original-title="youTube"] {
  background: #bb0000;
}

a[data-original-title="flickr"] {
  background: #ff0084;
}

a[data-original-title="rss"] {
  background: #fa9b39;
}

a[data-original-title="pinterest"] {
  background: #cb2027;
}

a[data-original-title="instagram"] {
  background: #3f5c9a;
}

a[data-original-title="vimeo"] {
  background: #aad450;
}

a[data-original-title="dribble"] {
  background: #ea4c89;
}

a[data-original-title="behance"] {
  background: #1759ff;
}

a[data-original-title="tumbler"] {
  background: #32506d;
}

a[data-original-title="stumbleupon"] {
  background: #eb4823;
}

/*Main Header*/

header#header {
  position: relative;
  z-index: 99;
}

#header.fixed-header,
#header.sticky-header {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.container .main-nav {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
}

.container .main-nav .main-navigation {
  display: none;
}

.container .main-nav .slicknav_menu {
  display: block;
}

.main-header .wide {
  padding: 0 15px;
}

.main-header .wide .main-nav {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0;
}

.main-header .wide .col-lg-8 {
  width: 75%;
}

.main-header .wide .col-lg-4 {
  width: 25%;
}

.main-header .main-navigation ul {
  margin: 0;
  padding: 0;
  text-align: right;
}

.main-header .main-navigation > ul > li {
  display: inline-block;
  margin: 0 -4px 0 0;
  padding: 0;
  position: relative;
}

.main-header .main-navigation > ul > li > a {
  font-size: 13px;
  color: #b6babf;
  line-height: 21px;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  display: block;
  padding: 19px 16px;
}

.main-header .main-navigation > ul > li ul {
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  text-align: left;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -moz-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.main-header .main-navigation > ul > li ul li {
  display: block;
  margin: 0;
  padding: 0;
  line-height: normal;
  position: relative;
}

.main-header .main-navigation > ul > li ul li a {
  display: block;
  line-height: normal;
  padding: 10px 12px;
  text-transform: capitalize;
}

.main-header .main-navigation > ul li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.main-header .main-navigation > ul > li > ul > li ul {
  left: 100%;
  top: 0;
}

/*Responsive Menu Style Start*/

.slicknav_menu {
  display: none;
}

.slicknav_btn {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  transition: background 0.3s;
}

.slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
  margin-top: 4px;
}

.slicknav_menu .slicknav_menutxt {
  display: none;
  float: left;
  color: #fff;
  font-weight: bold;
  line-height: 14px;
  border: none;
  margin-right: 6px;
}

.slicknav_menu .slicknav_icon {
  display: block;
  position: absolute;
  top: 14px;
  left: 5px;
  right: 5px;
  height: 2px;
  background: white;
}

.slicknav_menu .slicknav_icon:before,
.slicknav_menu .slicknav_icon:after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  content: "";
}

.slicknav_menu .slicknav_icon:before {
  top: -6px;
}

.slicknav_menu .slicknav_icon:after {
  bottom: -6px;
}

.slicknav_btn {
  background-color: #ff3264;
}

.slicknav_btn .slicknav_icon {
  -moz-transition: background 0.3s;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.slicknav_btn .slicknav_icon:before,
.slicknav_btn .slicknav_icon:after {
  -webkit-transition-duration: 0.3s, 0.3s;
  -moz-transition-duration: 0.3s, 0.3s;
  transition-duration: 0.3s, 0.3s;
  -webkit-transition-delay: 0.3s, 0s;
  -moz-transition-delay: 0.3s, 0s;
  transition-delay: 0.3s, 0s;
}

.slicknav_btn .slicknav_icon:before {
  -webkit-transition-property: top, transform;
  -moz-transition-property: top, transform;
  -webkit-transition-property: top, -webkit-transform;
  transition-property: top, -webkit-transform;
  -moz-transition-property: top, transform, -moz-transform;
  transition-property: top, transform;
  transition-property: top, transform, -webkit-transform, -moz-transform;
}

.slicknav_btn .slicknav_icon:after {
  -webkit-transition-property: bottom, transform;
  -moz-transition-property: bottom, transform;
  -webkit-transition-property: bottom, -webkit-transform;
  transition-property: bottom, -webkit-transform;
  -moz-transition-property: bottom, transform, -moz-transform;
  transition-property: bottom, transform;
  transition-property: bottom, transform, -webkit-transform, -moz-transform;
}

/* active state, i.e. menu open Start*/

.slicknav_btn.slicknav_open .slicknav_icon {
  background: none;
}

.slicknav_btn.slicknav_open .slicknav_icon:before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.slicknav_btn.slicknav_open .slicknav_icon:after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.slicknav_btn.slicknav_open .slicknav_icon:before,
.slicknav_btn.slicknav_open .slicknav_icon:after {
  background: #fff !important;
  -webkit-transition-delay: 0s, 0.3s;
  -moz-transition-delay: 0s, 0.3s;
  transition-delay: 0s, 0.3s;
}

/* active state, i.e. menu open End*/

.slicknav_menu .slicknav_no-text {
  margin: 0;
}

.slicknav_menu .slicknav_icon-bar {
  display: none;
  width: 1.125em;
  height: 2px;
  font-size: 19px;
  background: #fff;
}

.slicknav_menu:before {
  content: " ";
  display: table;
}

.slicknav_menu:after {
  content: " ";
  display: table;
  clear: both;
}

.slicknav_nav {
  clear: both;
  max-height: -webkit-calc(100vh - 162px);
  max-height: -moz-calc(100vh - 162px);
  max-height: calc(100vh - 162px);
}

.slicknav_nav ul {
  display: block;
}

.slicknav_nav li {
  display: block;
  margin-bottom: 0px;
}

.slicknav_nav .sub-dropdown li {
  margin-top: 4px !important;
}

.slicknav_nav .slicknav_arrow {
  font-size: 16px;
  margin: 0 0 0 0.4em;
  float: right;
  line-height: 30px;
}

.slicknav_nav .slicknav_item {
  cursor: pointer;
}

.slicknav_nav .slicknav_item a {
  display: inline;
}

.slicknav_nav .slicknav_row {
  display: block;
}

.slicknav_nav .slicknav_parent-link a {
  display: inline;
}

.slicknav_brand {
  float: left;
}

.slicknav_menu * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.slicknav_nav {
  color: #fff;
  margin: 0;
  padding: 10px;
  font-size: 0.875em;
  list-style: none;
  overflow: hidden;
  text-align: left;
  position: absolute;
  top: 100%;
  background: #fff;
  z-index: 2;
}

.slicknav_nav ul {
  list-style: none;
  padding: 0;
  margin: 0 0 0 0px;
  width: 100%;
  overflow: hidden;
}

.slicknav_nav {
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  width: 300px;
}

.slicknav_nav .slicknav_row a {
  padding: 5px 10px;
  margin: 2px 5px;
}

.slicknav_nav a {
  padding: 6px 10px;
  margin: 4px 0;
  text-decoration: none;
  color: #000;
  border: 1px solid rgba(225, 225, 225, 0.2);
  display: block;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
}

.slicknav_nav a,
.slicknav_nav a:focus,
.slicknav_nav a:hover {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: none;
}

.slicknav_nav .slicknav_txtnode {
  margin-left: 15px;
}

.slicknav_nav .slicknav_item a {
  padding: 0;
  margin: 0;
  border: none;
}

.slicknav_nav .slicknav_parent-link a {
  padding: 0;
  margin: 0;
}

.slicknav_brand {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  padding: 7px 12px;
  height: 44px;
}

a.slicknav_btn .slicknav_icon-bar {
  opacity: 1;
  visibility: visible;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a.slicknav_btn .slicknav_icon-bar:last-child {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

a.slicknav_btn .slicknav_icon-bar:first-child {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

a.slicknav_btn.slicknav_open .slicknav_icon-bar {
  opacity: 0;
  visibility: hidden;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a.slicknav_btn.slicknav_open .slicknav_icon-bar:last-child {
  position: absolute;
  bottom: 13px;
  left: 7px;
  opacity: 1;
  visibility: visible;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

a.slicknav_btn.slicknav_open .slicknav_icon-bar:first-child {
  position: absolute;
  top: 14px;
  right: 8px;
  visibility: visible;
  opacity: 1;
  width: 1.12em;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*Responsive Menu Style End*/

.transparent-header .wide .col-lg-8 {
  width: 45%;
}

.transparent-header .wide .col-lg-4 {
  width: 55%;
}

.main-header.transparent {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  padding: 30px 0 0;
}

.main-header.transparent .logo {
  margin-right: 50px;
}

.main-header.transparent .main-location ul li:last-child {
  margin-right: 0;
}

.main-header.transparent .main-location > ul > li > a {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 -5px;
  padding: 0 0 5px;
}

.main-header.transparent .login-option a.login-popup {
  font-weight: 600;
  font-size: 14px;
}

.main-header.transparent .login-option a.get-start-btn {
  margin: 0 0 0 50px;
}

.main-header.transparent .main-location ul li ul li:last-child a {
  border-radius: inherit;
}

.main-header.transparent .main-location ul {
  visibility: visible;
  opacity: 1;
  display: block;
}

.main-header.transparent .main-location ul li ul {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  padding: 10px 10px 10px 20px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 200%;
  left: 0;
  width: 160px;
  z-index: 1;
  border: inherit;
  visibility: hidden;
  opacity: 0;
}

.main-header.transparent .main-location ul li ul li .has-child {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 10px 10px 10px 20px;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 108%;
  width: 160px;
  z-index: 1;
  border: inherit;
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  border-top-left-radius: inherit;
}

.main-header.transparent .main-location ul li ul li .has-child li a {
  color: #fff;
}

.main-header.transparent .main-location ul li ul li {
  position: relative;
}

.main-header.transparent .main-location ul li ul li:hover .has-child {
  opacity: 1;
  visibility: visible;
}

.main-header.transparent .main-location ul li:hover ul {
  visibility: visible;
  opacity: 1;
  top: 130%;
}

.main-header.transparent .main-location ul li ul li {
  margin-bottom: 8px;
  text-align: left;
}

.main-header.transparent .main-location ul li ul li:last-child {
  margin: 0;
}

.main-header.transparent .main-location ul li ul li a {
  display: inline-block;
  color: #fff;
  text-transform: capitalize;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}

.main-header.transparent .main-location ul li ul li a i {
  margin-left: 15px;
  font-size: 16px;
  vertical-align: top;
}

.main-header.transparent .main-location ul li ul li:hover a {
  background-color: transparent;
}

.banner-sec {
  margin-top: -106px;
}

.main-header .logo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
  max-width: 200px;
  padding: 10px 0;
}

.main-header {
  background: #fff;
  position: relative;
  background: none;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.main-location {
  display: inline-block;
  vertical-align: middle;
}

.main-location > ul {
  margin: 0;
  padding: 0;
}

.main-location > ul > li {
  padding: 0;
  margin: 0 18px 0 0;
  list-style: none;
  display: inline-block;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: normal;
  position: relative;
}

.main-location ul li.cousine-notice {
  padding: 0 20px 12px 20px;
  color: #39b44d;
}

.main-location > ul > li > a {
  padding: 26px 21px 26px 0;
  margin: 0;
  list-style: none;
  display: block;
  font-size: 13px;
  color: #999;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: normal;
  font-weight: 700;
  position: relative;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-transform: uppercase;
}

.main-location > ul > li.location-has-children > a:after {
  position: absolute;
  right: 0;
  top: auto;
  content: "\f0d7";
  font-family: "icomoon";
  font-size: 9px;
  line-height: 18px;
}

.main-location > ul > li > a > i {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 12px;
  font-size: 16px;
}

/*Sub Menu*/

.main-location > ul > li.location-has-children.menu-open {
  position: relative;
  z-index: 12;
}

.main-location > ul > li.location-has-children.menu-open > a {
  z-index: 10;
}

.main-location > ul > li.location-has-children.menu-open > a:after {
  display: none;
}

.main-location > ul > li ul {
  position: absolute;
  left: -20px;
  top: 0;
  width: 229px;
  background: #fff;
  text-align: left;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  opacity: 1;
  visibility: visible;
  margin: 0;
  padding: 67px 0 0;
  border-radius: 6px;
  border: solid 1px #dfe4e5;
}

/*Transparent Header Style Start */

#header.transparent-header .main-header {
  background: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  -moz-transition: background-color 0.3s ease-in-out;
  -webkit-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.dark-logo,
#header.transparent-header.pinned .light-logo {
  display: none;
}

#header.transparent-header.pinned .dark-logo {
  display: block;
}

#header.transparent-header.pinned .main-header {
  background: #ffffff !important;
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

#header.transparent-header.pinned .main-header .main-navigation > ul > li > a,
#header.transparent-header.pinned .main-header .main-navigation ul ul li a,
#header.transparent-header.pinned .main-header .main-navigation ul ul ul li a,
#header.transparent-header.pinned .main-location > ul > li > a,
.transparent-header.pinned .user-dashboard-menu > ul > li > a,
.transparent-header.pinned
  .user-dashboard-menu
  > ul
  > li.user-dashboard-menu-children
  > a:after {
  color: #777 !important;
}

.transparent-header .main-header .main-nav {
  float: right;
  padding: 6px 20px 0 0;
}

.transparent-header
  .main-header
  .main-navigation
  > ul
  > li.menu-item-has-children
  > a {
  position: relative;
}

.transparent-header
  .main-header
  .main-navigation
  > ul
  > li.menu-item-has-children
  > a:before {
  content: "\e956";
  font-family: "icomoon";
  font-size: 17px;
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.transparent-header .main-header .login-option a.login-popup {
  color: #eb6825;
}

.transparent-header .main-header .login-option a.get-start-btn {
  background-color: #eb6825;
  border: solid 1px transparent;
}

.transparent-header .main-header .login-option a.get-start-btn:hover {
  background-color: #ffffff !important;
}

.transparent-header .main-header .main-navigation > ul > li > a {
  padding: 19px 24px;
}

.transparent-header .main-header .login-option a.get-start-btn {
  margin: 0 0 0 25px;
}

.transparent-header .main-location > ul > li > a {
  color: #fff;
}

.transparent-header
  .main-location
  > ul
  > li.location-has-children.menu-open
  > a {
  color: #999;
}

/*Transparent Header Style End*/

/*Header Fancy style Start*/

#header.fancy .main-header .wide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

#header.fancy.transparent-header .main-header .main-nav {
  float: none;
  padding: 0;
}

#header.fancy .main-header .nav-left {
  display: inline-block;
  text-align: right;
  padding-right: 20px;
  padding-top: 5px;
  width: 45%;
}

#header.fancy .main-header .logo {
  text-align: center;
  padding: 0;
  margin: -60px 0 0 0;
  position: relative;
  z-index: 9;
  width: 10%;
}

#header.fancy .main-header .logo figure {
  position: absolute;
  left: 50%;
  top: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

#header.fancy .main-header .nav-right {
  display: inline-block;
  text-align: left;
  padding-left: 0px;
  padding-top: 5px;
  width: 45%;
}

#header.fancy .main-header .login-option {
  float: none;
  display: inline-block;
  vertical-align: middle;
  padding: 19px 0;
}

.logged-in #header.fancy .main-header .login-option {
  padding: 0;
}

#header.fancy .main-header .login-option a.login-popup {
  margin-top: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

#header.fancy.transparent-header
  .main-header
  .login-option
  a.get-start-btn:hover {
  background-color: transparent !important;
}

#header.fancy.transparent-header .main-header .logo figure {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#header.fancy.transparent-header .main-header .logo figure img {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#header.fancy.transparent-header.pinned .main-header .logo figure {
  border-radius: 50%;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

#header.fancy.transparent-header.pinned .main-navigation ul ul {
  background: #fff !important;
}

#header.fancy.transparent-header.pinned .main-header {
  background-color: #fff !important;
}

#header.fancy.transparent-header.pinned
  .main-header
  .main-navigation
  > ul
  > li
  > a,
#header.fancy.transparent-header.pinned
  .main-header
  .main-navigation
  ul
  ul
  li
  a {
  color: #3d4d67 !important;
}

/*Header Fancy style End*/

/*Main Location Scroll Bar Start*/

@media (min-width: 992px) {
  .max-location-height,
  .main-location > ul > li.choose-location ul ul {
    max-height: 355px;
    overflow: hidden;
    overflow-y: auto;
  }
  .max-location-height::-webkit-scrollbar,
  .slicknav_nav::-webkit-scrollbar,
  .main-location > ul > li.choose-location ul ul::-webkit-scrollbar {
    width: 5px;
    height: auto;
  }
  .max-location-height::-webkit-scrollbar-button,
  .slicknav_nav::-webkit-scrollbar-button,
  .main-location > ul > li.choose-location ul ul::-webkit-scrollbar-button {
    display: none;
  }
  .max-location-height::-webkit-scrollbar-thumb,
  .slicknav_nav::-webkit-scrollbar-thumb,
  .main-location > ul > li.choose-location ul ul::-webkit-scrollbar-thumb {
    background: #00a79d;
    border: 3px none #ffffff;
    border-radius: 0px;
  }
  .max-location-height::-webkit-scrollbar-track,
  .slicknav_nav::-webkit-scrollbar-track,
  .main-location > ul > li.choose-location ul ul::-webkit-scrollbar-track {
    border: 0px none #ffffff;
    border-radius: 0px;
    background: #e1e1e1;
  }
  .max-location-height::-webkit-scrollbar-corner,
  .slicknav_nav::-webkit-scrollbar-corner,
  .main-location > ul > li.choose-location ul ul::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.main-location > ul > li > ul {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  transform: none;
  display: none;
}

.main-location > ul > li.menu-open > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  transform: none;
  display: block;
  z-index: 3;
}

.main-location .close-menu-location {
  position: absolute;
  right: 14px;
  top: 28px;
  cursor: pointer;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.main-location .choose-location .foodbakery-radius-location {
  position: absolute;
  top: 14px;
  right: 15px;
  cursor: pointer;
}

.main-location .choose-location .slider.slider-horizontal {
  width: 63%;
}

.main-location .choose-location .slider.slider-horizontal {
  margin-bottom: 5px;
}

.main-location .choose-location .select-popup {
  top: 35px;
}

.main-location .select-location .select-popup span {
  float: right;
}

/*Inner Sub Menu*/

.main-location > ul > li > ul li {
  position: relative;
}

.main-location > ul > li > ul li > span {
  background-color: #f1f1f1;
  border-radius: 15px;
  color: #999;
  font-size: 11px;
  line-height: 1.5;
  min-width: 30px;
  padding: 0px 5px;
  text-align: center;
  display: inline-block;
  right: 10px;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main-location > ul > li > ul li.location-has-children > a {
  position: relative;
}

.main-location > ul > li > ul li.location-has-children > a:after {
  position: absolute;
  right: 15px;
  top: auto;
  content: "\e315";
  font-family: "icomoon";
  font-size: 14px;
  line-height: 18px;
}

.main-location > ul > li ul ul {
  left: 100%;
  top: -100%;
  padding: 0;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
}

.main-location > ul > li.choose-location ul ul {
  border-radius: 0 0 6px 6px;
  border-top: none;
  left: -1px;
  opacity: 1;
  padding: 0;
  top: 100%;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  visibility: visible;
}

.main-location > ul > li ul > li:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  transform: none;
  z-index: 1;
}

.main-location > ul > li ul > li > ul li:last-child > a {
  border-radius: 0;
}

.main-location > ul > li > ul li {
  list-style: none;
  display: block;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: normal;
  position: relative;
}

.main-location > ul > li > ul li.popular-location {
  padding: 10px 20px 0;
}

.main-location > ul > li > ul li > a {
  padding: 12px 20px;
  margin: 0;
  list-style: none;
  display: block;
  font-size: 14px;
  color: #484848;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: normal;
  font-weight: 700;
  position: relative;
}

.main-location > ul > li > ul li > a img {
  width: 20px;
  display: inline-block;
  vertical-align: top;
  margin-right: 6px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.main-location > ul > li > ul li:hover > a img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.main-location > ul > li > ul li > a > i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

.main-location > ul > li > ul li:hover > a {
  background: #f6f8ff;
}

.main-location > ul > li > ul li:last-child > a {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.main-location > ul > li > ul li .foodbakery-input-cross {
  position: absolute;
  top: 12px;
  right: 15px;
}

.main-location > ul > li > ul li .foodbakery-input-cross-header {
  font-size: 8px;
  right: 35px;
  top: 13px;
  cursor: pointer;
}

.main-location > ul > li > ul li input[type="text"] {
  padding-right: 47px;
}

.main-location .select-location .input-filed {
  position: relative;
}

.main-location .select-location .input-filed input {
  height: 35px;
  background: #f6f8ff;
  border: #dfe4e5 solid 1px;
  border-left: 0;
  border-right: 0;
  padding-right: 30px;
}

.main-location .select-location .input-filed a {
  position: absolute;
  right: 13px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background: none;
  margin: 0;
  height: auto;
  width: auto;
  line-height: 0;
}

.location-overlay {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

/*Sub Menu 2*/

.main-location > ul > li.choose-location > ul {
  border-radius: 6px 6px 0 0;
  padding-bottom: 0;
}

.main-location > ul > li.choose-location h6 {
  margin: 0;
  padding: 12px 20px 5px;
  line-height: normal;
  font-weight: 700;
  color: #4a4a4a;
}

.main-location > ul > li.choose-location > ul li.location-search > a {
  padding: 6px 20px 3px;
  font-weight: 700;
}

/*Select Popup*/

.select-location .select-popup {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-top: 0;
  display: none;
  left: 0;
  padding: 10px 10px 15px 15px;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 99;
  margin-bottom: 10px;
  border-left: 0;
  border-right: 0;
}

.select-location .select-popup.popup-open {
  display: block;
  text-align: left;
}

.select-location .login-switch {
  display: inline-block;
  padding: 6px 10px 7px;
  text-align: center;
  vertical-align: top;
}

.select-location .location-close-popup {
  position: absolute;
  right: 12px;
  top: 14px;
  cursor: pointer;
}

.select-location p {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 5px;
}

.select-location p i {
  margin-right: 4px;
  display: inline-block;
  vertical-align: top;
  line-height: 19px;
}

.main-search .select-location .select-popup span {
  position: static;
  float: right;
  margin: 0;
  font-weight: 600;
}

.main-search .select-location .select-popup > span {
  margin-top: 4px;
}

.select-location .select-popup p {
  font-weight: 600;
}

.select-location .select-popup .location-close-popup {
  line-height: 19px;
  color: #39b44d;
}

.select-location .select-popup span span {
  position: relative;
  width: auto;
  bottom: 0;
  right: 0;
}

.select-location .slider-track {
  background: #eaeaea none repeat scroll 0 0;
  border-radius: 20px;
}

.select-location .slider-selection {
  background: #39b44d;
  border-radius: 20px;
}

.select-location .slider.slider-horizontal .slider-track {
  height: 11px;
}

.select-location .select-popup span {
  color: #ccc;
  display: inline-block;
  font-size: 14px;
  padding: 0;
}

.select-location .slider.slider-horizontal {
  width: 70%;
  margin-bottom: 10px;
}

.select-location .slider-handle {
  /* background: url("../../../../assets/images/ui-arrow.png") no-repeat; */
  height: 26px;
  top: -4px;
  width: 25px;
}

.select-location .select-popup {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  display: none;
  left: 0;
  padding: 10px 10px 15px 15px;
  position: absolute;
  top: 56px;
  width: 100%;
  z-index: 10;
}

.select-location .select-popup:before {
  border-bottom: 7px solid #e4e4e4;
  border-left: 7px solid rgba(0, 0, 0, 0);
  border-right: 7px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  margin: 0 -2px 0 0px;
  position: absolute;
  right: 16px;
  top: -7px;
}

.select-location .select-popup:after {
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid rgba(0, 0, 0, 0);
  border-right: 6px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  margin: 0 -1px 0 0px;
  position: absolute;
  right: 16px;
  top: -5px;
}

.select-location .select-popup .my-location {
  line-height: 18px;
}

.select-location .select-popup .my-location i {
  color: #39b44d;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 0;
}

.select-location .select-popup .my-location a {
  color: #39b44d;
  margin-left: 8px;
}

/* Header Popup Style */

/* Buy Theme Btn Style */

.main-header .login-area .login-option li a.buy-theme-btn {
  background-color: #39b44d;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  padding: 10px 15px;
  font-family: "Montserrat", sans-serif;
}

/* Sub Header style Start */

.sub-header {
  margin-bottom: 30px;
}

.sub-header.align-center {
  text-align: center;
}

.sub-header.align-left {
  text-align: left;
}

.sub-header.align-right {
  text-align: right;
}

.sub-header .page-title h1 {
  color: #fff;
  margin-bottom: 15px;
}

.sub-header p {
  font-size: 16px;
  color: #fff;
  line-height: 26px;
  margin-bottom: 0px;
}

/* Breadcrumba Style */

.breadcrumbs.align-left {
  text-align: left;
}

.breadcrumbs.align-center {
  text-align: center;
}

.breadcrumbs.align-right {
  text-align: right;
}

.breadcrumbs {
  padding: 10px 0 0 0;
  margin: 0 0 40px 0;
}

.breadcrumbs ul {
  margin: 0;
  padding: 0;
}

.breadcrumbs ul li {
  list-style: none;
  position: relative;
  color: #555;
  display: inline-block;
  vertical-align: middle;
  font: 400 13px/24px "Roboto", sans-serif;
  letter-spacing: 0;
  word-spacing: 0;
  text-transform: none;
  margin: 0 18px 0 0;
}

.breadcrumbs ul li:last-child {
  margin: 0;
}

.breadcrumbs ul li:after {
  clear: both;
  content: "";
  display: block;
}

.breadcrumbs ul li:before {
  position: absolute;
  content: "\e958";
  top: 1px;
  right: -17px;
  font-family: icomoon;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.breadcrumbs ul li:last-child:before {
  display: none;
}

.breadcrumbs ul li a {
  display: inline-block;
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #555;
  font: 400 13px/24px "Roboto", sans-serif;
  letter-spacing: 0;
  word-spacing: 0;
  text-transform: none;
}

/* Sub Header style Start */

/* Chosen Style */

.field-holder .chosen-container-single .chosen-single {
  border: 1px solid #edeff5;
}

.field-holder .chosen-container .chosen-drop {
  border: 1px solid #edeff5;
  border-top: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.field-holder .chosen-container-single .chosen-single:before {
  content: "\e313";
  position: absolute;
  right: 15px;
  top: 9px;
  font-family: "icomoon";
  color: #555;
  font-size: 14px;
}

.field-holder .chosen-container-single .chosen-single {
  padding: 10px 15px;
  height: 40px;
  position: relative;
  border-radius: 3px;
  background-color: #fdfdfd;
}

.field-holder .chosen-container-single .chosen-single span {
  color: #555;
  font-size: 14px;
  line-height: 20px;
}

.field-holder .chosen-container-active .chosen-single {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.field-holder .chosen-search input {
  display: none;
}

.field-holder .chosen-container .chosen-results li {
  padding: 6px 6px;
  color: #555;
  font-size: 14px;
}

.field-holder .chosen-container .chosen-results li.highlighted {
  color: #fff;
  background-image: none;
}

/* Payment Info Style */

.reservation-form .field-holder input.has-border[type="text"] {
  background-color: #fdfdfd;
  border: 1px solid #f0f0f0;
}

.reservation-form .payment-info {
  background-color: #fff;
  padding: 30px;
  border-radius: 3px;
}

.reservation-form .payment-info .element-title h5,
.reservation-form .payment-info .element-title h3,
.reservation-form .payment-info .element-title h2,
.reservation-form .payment-info .element-title h4 {
  text-transform: uppercase;
  margin: 0px;
}

.reservation-form .payment-info .element-title {
  margin-bottom: 12px;
}

.reservation-form .payment-info .element-title span {
  font-size: 14px;
  color: #2f313a;
  font-weight: 300;
}

/* Payment List Style */

.payment-info .payment-list {
  padding: 0px;
  margin: 0px;
}

.payment-info .payment-list li {
  border: medium none;
  -webkit-box-shadow: none;
  box-shadow: none;
  list-style: outside none none;
  margin: 0 0 15px;
  padding: 0 0 0 13px;
  text-shadow: none;
  display: inline-block;
}

.payment-info .payment-list li:first-child {
  padding-left: 0px;
}

.payment-info .payment-list li .payment-box {
  display: block;
}

.payment-info .payment-list li .payment-box input[type="radio"] {
  display: none;
}

.payment-info .payment-list li .payment-box label {
  position: relative;
  display: block;
  cursor: pointer;
  padding-left: 25px;
  margin-bottom: 0px;
}

.payment-info .payment-list li .payment-box label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  width: 12px;
  height: 12px;
  background-color: #d2d2d2;
  border-radius: 100%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
}

.payment-info .payment-list li .payment-box label img {
  border: 1px solid #d4d4d4;
}

.payment-info
  .payment-list
  li
  .payment-box
  input[type="radio"]:checked
  + label:before {
  content: "\e969";
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 1px solid #d8d8d8;
  background-color: #fff;
  color: #2f313a;
  font-family: "icomoon";
  font-size: 10px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  text-align: center;
}

/* Check Box Area */

.payment-info .checkbox-area input[type="checkbox"] {
  display: none;
}

.payment-info .checkbox-area {
  padding-top: 7px;
}

.payment-info .checkbox-area label {
  display: block;
  padding-left: 25px;
  position: relative;
  margin: 0px;
  font-size: 14px;
  color: #2f313a;
  cursor: pointer;
}

.payment-info .checkbox-area label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 16px;
  height: 16px;
  display: inline-block;
  background-color: #f9f9fa;
  border: 1px solid #d3d4d5;
  text-align: center;
  line-height: 16px;
}

.payment-info .checkbox-area input[type="checkbox"]:checked + label:before {
  content: "\e9af";
  font-size: 9px;
  color: #2f313a;
  font-family: "icomoon";
  line-height: 15px;
}

/* Social Network Style */

.reservation-form .social-network .field-holder {
  position: relative;
}

.reservation-form .social-network .field-holder input[type="text"] {
  padding-left: 35px;
}

.reservation-form .social-network .field-holder i {
  position: absolute;
  left: 14px;
  top: 14px;
  color: #555;
  font-size: 14px;
}

.reservation-form .social-network .field-holder .checkbox-area label {
  line-height: 22px;
  cursor: pointer;
}

.reservation-form .social-network .field-holder .checkbox-area label::before {
  top: 6px;
}

.reservation-form .social-network .checkbox-area {
  padding-top: 0px;
}

.reservation-form .social-network .send-btn {
  display: block;
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border-radius: 3px;
  border: none;
  width: 100%;
}

/* Reservation Form Style */

/* Order Sort Results Style */

.order-sort-results {
  background-color: #fff;
  padding: 15px 20px 10px 20px;
  margin-bottom: 20px;
}

.order-sort-results ul {
  padding: 0px;
  margin: 0px;
}

.order-sort-results ul li {
  list-style: none;
  padding: 0;
  margin: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  position: relative;
}

.order-sort-results ul li a {
  display: block;
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  font-size: 14px;
  font-weight: 300;
  color: #2f313a;
  line-height: 18px;
  margin-bottom: 12px;
  position: relative;
  padding-left: 28px;
}

.order-sort-results ul li a i {
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Order Sort Results Style */

.message-box {
  padding: 25px;
  text-align: center;
  margin-bottom: 20px;
  border: 1px solid #d7d7d7;
}

.message-box strong {
  display: block;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 8px;
}

.message-box span {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: block;
  margin-bottom: 20px;
}

.message-box .request-btn {
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border: 1px solid #fff;
  border-radius: 4px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  line-height: 32px;
}

.message-warning {
  background-color: #fa9918;
}

.message-success {
  background-color: #00a474;
}

/* pagination Style Start */

.pagination > li {
  display: inline-block;
  margin: 0px 5px 5px 0;
}

.pagination > li > a,
.pagination > li > span {
  font-size: 14px;
  color: #555;
  margin-left: -1px;
  height: 26px;
  border: 1px solid #dddddd;
  background-color: transparent;
  padding: 0 10px;
  line-height: 23px;
  text-align: center;
  border-radius: 3px;
  font-weight: 400;
  display: block;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #fff;
  border-color: transparent;
  color: #fff;
  z-index: 0;
  -webkit-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus,
.pagination > .active > span > a {
  background-color: #fff;
  border-color: transparent;
  -webkit-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.page-navigation.navigation ul li {
  margin-left: -4px;
}

.pagination > li > a.prev {
  margin-right: 6px;
  padding: 0 6px;
}

.pagination > li > a.prev i {
  display: inline-block;
  margin-right: 6px;
}

.pagination > li > a.next {
  margin-left: 6px;
  padding: 0 6px;
}

.pagination > li > a.next i {
  display: inline-block;
  margin-left: 6px;
}

.pagination-sm .prev,
.pagination-sm .next {
  padding: 0;
  border: 0;
  width: auto;
}

.pagination > .active > span a,
.pagination li span a {
  display: block;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* pagination Style End */

/* resturant listing start */

.sub-header .text-holder {
  margin: 0 0 10px 0;
}

.sub-header .text-holder h2 {
  color: #fff;
  margin: 0 0 10px 0;
  font: 700 28px/30px "Roboto", sans-serif;
  letter-spacing: 0;
  word-spacing: 1px;
  text-transform: none;
}

.sub-header .text-holder p {
  color: #fff;
  margin: 0;
  font: 400 16px/22px "Roboto", sans-serif;
  letter-spacing: 0;
  word-spacing: 0;
  text-transform: none;
}

/* resturant listing end */

/* how it work */

.image-frame.fancy.align-left {
  text-align: left;
}

.image-frame.fancy .img-holder {
  margin: 25px 0 0;
  padding-left: 25px;
}

.image-frame.fancy .img-holder figure {
  position: relative;
  z-index: 1;
}

.image-frame.fancy .img-holder figure:after {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  border: 10px solid #dcdee4;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.image-frame.fancy .img-holder figure:before {
  content: "";
  position: absolute;
  top: -24px;
  left: -25px;
  border: 2px solid #dfe1e9;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.image-frame.fancy .img-holder figure img {
  width: 100%;
  border: 4px solid #fff;
}

.image-frame.fancy .text-holder h2 {
  font-size: 22px;
  line-height: 30px;
  text-transform: none;
  margin-bottom: 30px;
}

.image-frame.fancy .text-holder p {
  color: #555;
  font-size: 14px;
  margin-bottom: 25px;
}

.image-frame.fancy .text-holder .default-btn {
  display: inline-block;
}

.image-frame.fancy .text-holder .default-btn a {
  display: inline-block;
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 30px;
  border-radius: 20px;
  font-size: 14px;
  line-height: normal;
}

.video-holder.has-border {
  border: 4px solid #fff;
}

.section-title.align-center,
.cs-section-title.align-center {
  text-align: center;
}

.section-title.align-left,
.cs-section-title.align-left {
  text-align: left;
  margin-bottom: 43px;
}

.section-title.align-right,
.cs-section-title.align-right {
  text-align: right;
}

.section-title,
.cs-section-title {
  margin-bottom: 54px;
}

.section-title h3,
.cs-section-title h3 {
  margin-bottom: 8px;
}

.section-title p,
.cs-section-title p {
  margin: 0;
}

.icon-boxes {
  margin-bottom: 30px;
}

.icon-boxes.top-center {
  text-align: center;
}

.icon-boxes.top-right {
  text-align: right;
}

.icon-boxes.top-left {
  text-align: left;
}

.icon-boxes.top-center .img-holder,
.icon-boxes.top-right .img-holder,
.icon-boxes.top-left .img-holder {
  margin: 0 0 20px;
  width: 100%;
}

.icon-boxes.top-center .text-holder,
.icon-boxes.top-right .text-holder,
.icon-boxes.top-left .text-holder {
  width: 100%;
}

.icon-boxes .img-holder figure i {
  font-size: 30px;
  color: #a4a6ad;
  border: 1px solid;
  border-radius: 50%;
  height: 67px;
  width: 67px;
  line-height: 67px !important;
  text-align: center;
  display: inline-block;
}

.icon-boxes.has-circle .img-holder {
  width: 68px;
  height: 68px;
  border: 1px solid #39b44d;
  border-radius: 50%;
  padding: 3px;
}

.icon-boxes.has-circle .img-holder figure i {
  background: #39b44d;
  color: #fff;
  border: 0;
  width: 60px;
  height: 60px;
}

.icon-boxes .img-holder {
  float: left;
  margin-right: 20px;
}

.icon-boxes .text-holder {
  overflow: hidden;
}

.icon-boxes .text-holder p,
.icon-boxes .text-holder span {
  color: #a1a3aa;
  font-size: 14px;
  line-height: 22px;
}

.icon-boxes.has-border .text-holder span {
  font: Normal 14px/22px "Roboto", sans-serif;
  letter-spacing: 0px;
  color: #999;
}

/* Icon-boxes Modern style start */

.cs-icon-boxes-list {
  overflow: hidden;
}

.icon-boxes.modern figure {
  border: 2px solid #eb6825;
  border-radius: 100%;
  position: relative;
  height: 126px;
  line-height: 116px;
  display: inline-block;
  width: 126px;
}

.icon-boxes.modern figure:before {
  background-color: #fff;
  border-color: #eb6825;
  border-radius: 0 22px 22px 0;
  border-style: solid;
  border-width: 2px 2px 2px 0;
  bottom: 0;
  content: "";
  height: 30px;
  position: absolute;
  right: 4px;
  width: 16px;
  -webkit-transform: rotate(44deg);
  -moz-transform: rotate(44deg);
  -ms-transform: rotate(44deg);
  transform: rotate(44deg);
}

.icon-boxes.modern figure img {
  max-width: 47px;
}

.icon-boxes.modern .text-holder {
  overflow: visible;
  padding: 0 50px;
}

.cs-icon-boxes-list
  [class*="col-"]:last-child
  .icon-boxes.modern
  .circular-steps:before {
  display: none;
}

.icon-boxes.modern .text-holder p {
  display: inline-block;
  margin: 0 0 35px;
  width: 100%;
}

.icon-boxes.modern .text-holder h3 a {
  font-size: 18px !important;
  color: #333 !important;
}

.cs-icon-boxes-list.classic-view {
  margin: 0 -41px;
}

.cs-icon-boxes-list {
  counter-reset: mega-step, mini-step;
}

.cs-icon-boxes-list [class*="col-"] {
  position: relative;
  counter-increment: mega-step;
}

.cs-icon-boxes-list .icon-boxes.modern {
  min-height: 277px;
}

.cs-icon-boxes-list .icon-boxes.modern:before {
  content: counter(mega-step);
  display: block;
  text-align: center;
  color: #aaaaaa;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 30px;
  height: 30px;
  background-color: #e7ecee;
  border-radius: 100%;
  line-height: 30px;
  z-index: 1;
  font-size: 14px;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.cs-icon-boxes-list .icon-boxes.modern:after {
  position: absolute;
  content: "";
  bottom: 13px;
  left: 50%;
  height: 1px;
  width: 100%;
  background: #e7ecee;
}

.cs-icon-boxes-list [class*="col-"]:last-child .icon-boxes.modern:after {
  display: none;
}

.cs-icon-boxes-list [class*="col-"]:nth-child(4n + 4) .icon-boxes.modern:after {
  display: none;
}

/* Icon-boxes Modern style end */

/* Icon-boxes has-border style start */

.icon-boxes.has-border .img-holder {
  width: 63px;
  height: 63px;
  border: 1px solid #39b44d;
  border-radius: 50%;
  padding: 5px;
  line-height: normal;
  margin-right: 15px;
}

.icon-boxes.has-border .img-holder figure {
  height: 100%;
  width: 100%;
  display: inline-block;
}

.icon-boxes.has-border .img-holder figure img {
  width: 100%;
}

.icon-boxes.has-border .img-holder figure a {
  background: #e51a1b;
  border-radius: 100%;
  display: block;
  padding: 10px;
  height: 100%;
  width: 100%;
}

.icon-boxes.has-border .img-holder figure i {
  background: #39b44d;
  color: #fff;
  border: 0;
  width: 60px;
  height: 60px;
}

.icon-boxes.has-border .text-holder {
  padding-top: 10px;
}

/* Icon-boxes has-border style end */

/* swipper slider style */

.company-holder .company-logo {
  background-color: #fff;
  text-align: center;
  width: 163px;
  height: 153px;
  position: relative;
  margin: auto;
}

.company-holder .company-logo figure {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.company-holder .company-logo figure a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  height: 100%;
}

.company-holder .company-logo figure a img {
  display: block;
  margin: auto;
  max-width: 100%;
}

.company-holder .swiper-button-next,
.company-holder .swiper-button-prev {
  position: absolute;
  top: -23%;
  right: 3px;
  left: auto;
  background-image: none;
  margin: 0;
}

.company-holder .swiper-button-next i {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 0;
  right: 14px;
  color: #c3c3c4;
  border-radius: 50%;
  border: 1px solid #c3c3c4;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 29px;
  font-size: 14px;
}

.company-holder .swiper-button-prev i {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 0;
  right: 50px;
  color: #c3c3c4;
  border-radius: 50%;
  border: 1px solid #c3c3c4;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 29px;
  font-size: 14px;
}

.company-holder .swiper-button-next:hover i,
.company-holder .swiper-button-prev:hover i {
  background-color: #fff;
  border-color: transparent;
}

/*Company-holder grid_view Style Start */

.company-holder.grid-view .text-holder .list-option {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
}

.company-holder.grid-view .company-logo {
  background: none;
}

.company-holder.grid-view .text-holder {
  text-align: center;
}

.company-holder.grid-view .text-holder .post-title h6 {
  margin-bottom: 0;
}

.company-holder.grid-view figure {
  width: 91px;
}

.company-holder.grid-view .rating-star .rating-box:before,
.company-holder.grid-view .rating-star:before {
  font-size: 14px;
}

.company-holder.grid-view span.reviews {
  font-size: 14px;
  color: #555;
}

/*Company-holder grid_view Style Start */

/*Company Fancy Logo Style Start */

.company-holder.fancy {
  position: relative;
}

.company-holder.fancy .swiper-container {
  padding: 0 10px 0;
}

.company-holder.fancy .company-logo {
  border-radius: 2px;
  margin-bottom: 15px;
  height: 128px;
  -webkit-box-shadow: 0px 0 24px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0 24px rgba(0, 0, 0, 0.1);
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.company-holder.fancy .swiper-slide:hover .company-logo {
  border-radius: 0 15px 0 15px;
}

.company-holder.fancy .text-holder .post-title a {
  color: #2f313a !important;
}

.company-holder.fancy .text-holder .post-title h6 {
  margin-bottom: 0;
}

.company-holder.fancy .fancy-button-prev,
.company-holder.fancy .fancy-button-next {
  color: #dcdcdc;
  position: absolute;
  top: 50%;
  left: -45px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 36px;
  cursor: pointer;
  z-index: 9;
}

.company-holder.fancy .fancy-button-next {
  right: -45px;
  left: auto;
}

.company-holder.fancy .rating-star:before,
.company-holder.fancy .rating-star .rating-box:before {
  font-size: 14px;
}

.company-holder.fancy .text-holder .reviews {
  font-size: 11px;
  color: #555;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-left: 2px;
}

.company-holder.fancy .company-rating .list-option {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-left: 4px;
}

.company-holder.fancy .company-rating .list-option .shortlist-btn i {
  display: inline-block;
  vertical-align: middle;
}

.company-holder.fancy .company-rating {
  font-size: 13px;
  color: #999;
}

.main-search.classic .col-lg-12.col-md-12.col-sm-12.col-xs-12 {
  margin-right: 0;
}

/*Company Fancy Logo Style End */

/*Company simple Logo Style start */

.company-holder.simple .company-logo {
  display: inline-block;
  height: auto;
  margin-bottom: 40px;
  background: none;
  width: 186px;
}

.company-holder.simple .company-logo figure {
  position: inherit;
}

.company-holder.simple .company-logo figure a {
  margin-bottom: 20px;
  display: block;
}

.company-holder.simple .company-logo .text-holder .post-title h6 {
  margin-bottom: 0;
}

.company-holder.simple .company-logo .text-holder .post-title h6 a {
  font-weight: 600 !important;
  color: #2f313a !important;
  letter-spacing: 0 !important;
}

.company-holder.simple .company-logo .company-rating {
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  color: #555;
}

.company-holder.simple
  .company-logo
  .text-holder
  .company-rating
  .rating-star
  .rating-box:before {
  font-size: 14px;
  color: #e51a1b;
}

.company-holder.simple
  .company-logo
  .text-holder
  .company-rating
  .rating-star:before {
  font-size: 14px;
  color: #e51a1b;
}

.company-holder.simple .company-logo .list-option {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 5px;
}

.company-holder.simple .company-logo .rating-star {
  line-height: unset;
}

.company-holder.simple .company-logo .company-rating .reviews {
  font-size: 14px;
  color: #555;
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
}

.company-holder.simple .company-logo .company-rating .icon-spinner {
  display: inline-block;
  position: initial;
  vertical-align: middle;
}

.company-holder.simple .company-logo .list-option i {
  vertical-align: middle;
}

/*Company simple Logo Style start */

/* how it work */

/* faq page start */

.faqs {
  margin-bottom: 50px;
}

.faqs .faq.panel-group:last-child {
  border-bottom: 0;
}

.faq .element-title {
  margin: 0 0 18px 0;
}

.faq .element-title h5 {
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin: 0;
}

.faq.panel-group {
  margin: 0 0 34px 0;
  border-bottom: 1px solid #e2e4e9;
}

.faq.panel-group .panel {
  border-top: 1px solid #e2e4e9;
  border-radius: 0;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
}

.faq.panel-group .panel .panel-heading {
  border: none;
  padding: 0;
  border-radius: 0;
  border-top: 1px solid #edeff4;
}

.faq.panel-group .panel .panel-heading + .panel-collapse .panel-body {
  border: 0;
  padding: 0 25px 13px 20px;
}

.faq.panel-group .panel .panel-heading + .panel-collapse .panel-body p {
  margin: -4px 0 0 0;
  color: #555;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 0.5px;
  word-spacing: 1px;
}

.faq.panel-group .panel-heading h4 a,
.faq.panel-group .panel-heading a {
  font: 400 14px/24px "Roboto", sans-serif !important;
  letter-spacing: 0 !important;
  word-spacing: 1px !important;
  text-transform: none !important;
  display: inline-block;
  padding: 8px 20px 7px;
  position: relative;
  width: 100%;
}

.faq.panel-group .panel-heading a.collapsed {
  color: #2f313a !important;
}

.faq.panel-group .panel-heading a.collapsed:before {
  content: "\e958";
}

.faq.panel-group .panel-heading a:before {
  content: "\e956";
  font-family: "icomoon";
  font-size: 17px;
  left: -5px;
  line-height: 21px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 1px 0 0 0;
}

/* faq page end */

/*Blog Large Style*/

.blog-large .blog-post {
  margin: 0 0 60px;
}

.blog-large .img-holder {
  margin: 0 0 20px;
}

.blog .img-holder {
  position: relative;
}

.blog-large .img-holder img {
  width: 100%;
}

.blog-large .text-holder {
  position: relative;
}

.blog .post-title {
  margin: 0 0 10px;
}

.blog .post-title h3,
.blog .post-title h4 {
  margin: 0;
}

/*Social Media Style*/

.blog-large .social-media {
  position: absolute;
  right: 0;
  top: 0;
}

.blog .social-media {
  margin: 0;
}

.blog .social-media li {
  display: inline-block;
}

.blog .social-media li a {
  background: none;
  border: 1px solid #3a5898;
  border-radius: 100%;
  font-size: 14px;
  margin: 0 -4px 0 8px;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  text-align: center;
  width: 32px;
}

.blog .social-media li a[data-original-title="facebook"],
.blog-detail .social-media li a[data-original-title="facebook"] {
  border-color: #3a5898;
  color: #3a5898;
}

.blog .social-media li a[data-original-title="twitter"],
.blog-detail .social-media li a[data-original-title="twitter"] {
  border-color: #54acec;
  color: #54acec;
}

.blog .social-media li a[data-original-title="google+"],
.blog-detail .social-media li a[data-original-title="google+"] {
  border-color: #dd4a3a;
  color: #dd4a3a;
}

.blog .social-media li a[data-original-title="pinterest"],
.blog-detai .social-media li a[data-original-title="pinterest"] {
  border-color: #bd071e;
  color: #bd071e;
}

.blog .social-media li a[data-original-title="tumbler"],
.blog-detail .social-media li a[data-original-title="tumbler"] {
  border-color: #2c394c;
  color: #2c394c;
}

.blog .social-media li a[data-original-title="dribble"],
.blog-detail .social-media li a[data-original-title="dribble"] {
  border-color: #f26798;
  color: #f26798;
}

.blog .social-media li a[data-original-title="stumbleupon"],
.blog-detail .social-media li a[data-original-title="stumbleupon"] {
  border-color: #eb4924;
  color: #eb4924;
}

.blog .social-media li a.cs-more,
.blog-detail .social-media li a.cs-more {
  border-color: #ff5c3e;
  color: #ff5c3e;
}

/*Social Media Style End*/

.blog-large .author-info {
  margin: 0 0 10px;
}

.blog-large .text-holder .author-info p {
  margin: 0;
  font-size: 14px;
  color: #2f313a;
  line-height: normal;
}

.blog-large .author-info p a {
  color: #39b44d;
  font-weight: 600;
  margin: 0 5px;
  display: inline-block;
}

.blog .text-holder > p {
  color: #555;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin: 0 0 18px;
}

.blog-large .read-more {
  border-radius: 5px;
  font-size: 14px;
  color: #39b44d;
  font-weight: 600;
  border: 1px solid #39b44d;
  display: inline-block;
  line-height: normal;
  padding: 8px 25px;
}

.blog .swiper-button-prev,
.blog .swiper-button-next {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #ebedf3;
  display: inline-block;
  font-size: 20px;
  height: 39px;
  left: 20px;
  line-height: 39px;
  text-align: center;
  width: 39px;
}

.blog .swiper-button-next {
  left: auto;
  right: 20px;
}

/*Blog Large Style End*/

/*Blog Medium Style Start*/

.blog-medium .blog-post figure img {
  width: 100%;
}

.blog-medium .blog-post .text-holder {
  overflow: hidden;
}

.blog-medium .blog-post {
  border-bottom: 1px solid #dfe1e7;
  padding: 0 0 30px;
  margin: 0 0 30px;
}

.blog-medium .img-holder {
  background-color: #fff;
  padding: 2px;
  float: right;
  margin: 0 0 0 50px;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 215px;
}

.blog .post-options {
  margin: 0;
  padding: 0;
}

.blog-medium .post-options li {
  list-style: none;
  font-weight: 600;
  display: inline-block;
  color: #2f313a;
  font-size: 14px;
}

.blog-medium .post-options li > a {
  margin-right: 20px;
}

.blog-medium .post-options li span {
  color: #555;
  font-weight: 400;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.blog-medium .post-options li span:before {
  background-color: #5b5d66;
  border-radius: 100%;
  position: absolute;
  left: -10px;
  top: 8px;
  content: "";
  height: 2px;
  width: 2px;
}

.blog-medium .read-more {
  color: #39b44d;
  font-weight: 600;
}

.blog-medium .read-more i {
  margin: 0 0 0 5px;
  vertical-align: middle;
  color: #39b44d;
  font-weight: 300;
}

.blog-medium .swiper-button-prev,
.blog-medium .swiper-button-next {
  background: rgba(0, 0, 0, 0.4);
  border: none;
  font-size: 14px;
  height: 23px;
  left: 2px;
  line-height: 23px;
  width: 23px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
}

.blog-medium .swiper-button-next {
  right: 2px;
  left: auto;
}

.blog-medium .text-holder > p {
  margin: 0 0 10px;
}

/*Blog Medium Style End*/

/*Blog Masonry Style Start*/

.blog-masonry {
  width: 100%;
  float: left;
}

.blog-masonry .img-holder figure {
  overflow: hidden;
}

.blog-masonry .img-holder img {
  width: 100%;
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.blog-masonry .blog-post:hover .img-holder img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.blog-masonry .text-holder {
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  padding: 25px 22px;
}

.blog-masonry .author-info {
  margin: 0 0 10px;
}

.blog-masonry .author-info figure {
  display: inline-block;
  vertical-align: middle;
  width: 38px;
}

.blog-masonry .author-info figure img {
  border-radius: 100%;
}

.blog-masonry .post-options {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 86.333%;
}

.blog-masonry .post-options li {
  list-style: none;
  font-size: 14px;
  color: #555;
  line-height: normal;
}

.blog-masonry .post-options li i {
  margin: 0 8px 0 0;
  vertical-align: middle;
}

.blog-masonry .post-options li span {
  color: #555;
  font-weight: 400;
  margin: 0 0 0 20px;
  position: relative;
}

.blog-masonry .post-options li span:before {
  background-color: #d0d1d4;
  border-radius: 100%;
  content: "";
  height: 3px;
  left: -10px;
  position: absolute;
  top: 8px;
  width: 3px;
}

.blog-masonry .post-title {
  border-bottom: 1px solid #eaeaea;
  padding: 0 0 15px;
}

.blog-masonry .read-more {
  color: #39b44d;
  display: block;
  font-size: 14px;
  letter-spacing: 0.5px;
  outline: none;
  position: relative;
  text-decoration: none;
}

.blog-masonry .read-more:before {
  position: absolute;
  right: 0;
  top: 0;
  content: "\ec01";
  font-family: "icomoon";
  font-size: 14px;
  color: #3b3b3b;
}

.blog-masonry .blog-post {
  margin: 0 0 30px;
}

.blog-masonry .swiper-button-prev,
.blog-masonry .swiper-button-next {
  background: rgba(27, 27, 27, 0.9);
  border: none;
  bottom: 10px;
  font-size: 14px;
  height: 27px;
  left: 10px;
  line-height: 27px;
  top: auto;
  width: 27px;
}

.blog-masonry .swiper-button-next {
  left: auto;
  right: 10px;
}

/*Blog Masonry Style End*/

/*Blog Masonry Style Start*/

.blog-detail .author-info {
  margin: 0 0 15px;
}

.blog-detail .author-info figure {
  float: left;
  margin: 0 10px 0 0;
  width: 38px;
}

.blog-detail .author-info figure img {
  border-radius: 100%;
}

.blog-detail .author-info .text-holder {
  overflow: hidden;
}

.blog-detail .author-info p {
  display: inline-block;
  line-height: normal;
  vertical-align: top;
  margin: 0;
  font-size: 14px;
  color: #2f313a;
  font-weight: 300;
}

.blog-detail .author-info .name {
  font-weight: 700;
  color: #39b44d;
  display: block;
  padding: 3px 0 0;
}

.blog-detail .post-options {
  margin: 0;
  display: inline-block;
  vertical-align: bottom;
}

.blog-detail .post-options li {
  list-style: none;
  position: relative;
  display: inline-block;
  margin: 0 0 0 20px;
  line-height: normal;
  vertical-align: middle;
}

.blog-detail .post-options li:before {
  background-color: #d2d4dc;
  left: -10px;
  top: 5px;
  position: absolute;
  height: 10px;
  content: "";
  width: 1px;
}

.blog-detail .post-options li i {
  color: #b7b9bf;
  margin: 0 5px 0 0;
  vertical-align: middle;
}

.blog-detail .title-area {
  margin: 0 0 15px 0;
}

.blog-detail .title-area h2 {
  font-size: 30px;
  margin: 0;
}

.blog-detail .title-area span {
  display: block;
  padding: 10px 0 0;
  font-size: 20px;
  color: #2f313a;
  line-height: 30px;
}

.blog-detail .social-media {
  margin: 0 0 25px -5px;
}

.blog-detail .social-media li {
  display: inline-block;
}

.blog-detail .social-media li a {
  background: none;
  border: 1px solid #3a5898;
  border-radius: 100%;
  font-size: 14px;
  margin: 0 -4px 0 8px;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  text-align: center;
  width: 32px;
}

.blog-detail .social-media li a[data-original-title="facebook"] {
  border-color: #3a5898;
  color: #3a5898;
}

.blog-detail .social-media li a[data-original-title="google+"] {
  border-color: #dd4a3a;
  color: #dd4a3a;
}

.blog-detail .social-media li a[data-original-title="pinterest"] {
  border-color: #bd071e;
  color: #bd071e;
}

.blog-detail .main-post {
  margin: 0 0 30px;
}

.blog-detail .rich-editor-text > p {
  margin: 0 0 25px;
}

.blog-detail .main-post figure {
  margin: 0;
}

.blog-detail .main-post img {
  width: 100%;
}

.blog-detail .blockquote-hloder {
  border: 4px solid #39b44d;
  margin: 0 20px 0 0;
  float: left;
  line-height: 26px;
  padding: 20px 19px;
  position: relative;
  width: 238px;
}

.blog-detail .blockquote-hloder:before {
  background-color: #ebedf3;
  color: #39b44d;
  content: "\ea34";
  font-family: "icomoon";
  font-size: 24px;
  left: 50%;
  margin: 0 0 0 -16px;
  position: absolute;
  text-align: center;
  top: -15px;
  -webkit-transform: scale(-1);
  -moz-transform: scale(-1);
  -ms-transform: scale(-1);
  transform: scale(-1);
  width: 32px;
}

.blog-detail .blockquote-hloder .author-info {
  margin: 0;
}

.blog-detail blockquote {
  border-left: none;
  border-bottom: 2px solid #d0d3dc;
  position: relative;
  margin: 0 0 18px;
  color: #2f313a;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  padding: 0 18px 18px;
}

.blog-detail blockquote p {
  display: inline;
}

.blog-detail blockquote:before {
  content: "''";
  margin: -6px -3px 0 3px;
  float: left;
  -webkit-transform: scale(-1);
  -moz-transform: scale(-1);
  -ms-transform: scale(-1);
  transform: scale(-1);
  position: absolute;
  left: 0;
  top: -2px;
  font-size: 28px;
}

.blog-detail blockquote:after {
  content: "''";
  display: inline-block;
  left: 5px;
  position: relative;
  bottom: -20px;
  font-size: 28px;
}

.blog-detail .blockquote-hloder .author-info p {
  color: #2f313a;
  font-weight: 700;
  text-transform: uppercase;
  width: 75%;
}

.blog-detail .blockquote-hloder .author-info p span {
  color: #8b8e9d;
  font-size: 14px;
  display: block;
  font-style: italic;
  font-weight: 400;
  padding: 5px 0 0;
  text-transform: capitalize;
}

.blog-detail .tags-list {
  border-bottom: 1px solid #dbdde4;
  border-top: 1px solid #dbdde4;
  padding: 12px 0;
  margin: 0 0 50px;
}

.blog-detail .tags-list h6 {
  margin: 0 0 0 0;
  color: #2f313a;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
}

.blog-detail .tags-list h6 ~ ul {
  margin: 0 0 0 10px;
}

.blog-detail .tags-list ul {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}

.blog-detail .tags-list li {
  list-style: none;
  display: inline-block;
  margin: 0 0 0 15px;
  line-height: normal;
  position: relative;
}

.blog-detail .tags-list li:before {
  background-color: #d2d4dc;
  content: "";
  height: 10px;
  left: -10px;
  position: absolute;
  top: 6px;
  width: 1px;
}

.blog-detail .tags-list li:first-child {
  margin-left: 0;
}

.blog-detail .tags-list li:first-child:before {
  display: none;
}

.blog-detail .tags-list li a {
  color: #2f313a;
  font-size: 14px;
  font-weight: 300;
}

.blog-detail .related-post {
  margin: 0 0 40px;
}

.related-post-holder .swiper-slide {
  margin: 0px;
}

.blog-detail .related-post .post-options li {
  color: #2f313a;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
}

.blog-detail .related-post .post-options li:first-child {
  margin-left: 0;
}

.blog-detail .related-post .post-options li:first-child:before {
  display: none;
}

.blog-detail .post-options li i {
  color: #b7b9bf;
}

.blog-detail .element-title {
  margin: 0 0 30px;
}

.blog-detail .element-title h4 {
  margin: 0;
}

.related-post .img-holder img {
  width: 100%;
}

.related-post .text-holder {
  padding: 15px 0 0 0;
}

.blog-detail .related-post .post-options {
  margin: 0 0 8px;
}

.related-post .post-title {
  margin: 0 0 5px;
}

.related-post .post-title h3,
.related-post .post-title h4,
.related-post .post-title h5,
.related-post .post-title h6 {
  margin: 0;
}

.related-post .post-title h5 {
  line-height: normal;
}

.related-post .text-holder p {
  font-size: 14px;
  color: #555;
  line-height: 22px;
  border-bottom: 1px solid #dee0e6;
  margin: 0 0 8px;
  padding: 0 0 12px;
}

.related-post .read-more {
  font-size: 14px;
  color: #39b44d;
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
  outline: none;
}

.related-post .read-more i {
  margin: 0 0 0 8px;
}

.blog-detail .swiper-container {
  position: initial;
}

.blog-detail .swiper-button-prev,
.blog-detail .swiper-button-next {
  background: none;
  left: auto;
  right: 50px;
  top: 0;
  margin: 0;
  height: 29px;
  border: 1px solid #dadce3;
  line-height: 29px;
  text-align: center;
  font-size: 14px;
  color: #a0a2a9;
}

.blog-detail .swiper-button-next {
  right: 15px;
}

/* Comment Style*/

.element-title .separator {
  height: 2px;
  background-color: #dee0e6;
  margin-top: 20px;
}

.comments .element-title h3 {
  margin-bottom: 15px;
}

.comments .element-title h4 {
  padding-bottom: 15px;
  border-bottom: 1px solid #dee0e6;
}

.comments ul {
  margin: 0 0 22px 0;
  padding: 0;
}

.comments ul li {
  list-style: none;
}

.comments ul li:after {
  clear: both;
  content: "";
  display: block;
}

.comments ul li .thumb-list {
  margin: 0 0 30px;
}

.comments ul li .thumb-list .img-holder {
  float: left;
  margin: 0 20px 0 0;
}

.comments ul li .thumb-list .img-holder figure {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  outline: none;
  display: inline-block;
}

.comments ul li .thumb-list .img-holder figure img {
  border-radius: 50%;
}

.comments ul li .thumb-list .text-holder {
  overflow: hidden;
}

.comments ul li .thumb-list .text-holder h6 {
  display: inline-block;
  color: #2f313a;
  letter-spacing: 0;
  vertical-align: top;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.comments ul li .thumb-list .text-holder .reply-btn {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  float: right;
  font-size: 13px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  border: 1px solid;
  border-radius: 15px;
  padding: 0 16px 0 30px;
  text-align: center;
  position: relative;
}

.comments ul li .thumb-list .text-holder .reply-btn:before {
  position: absolute;
  content: "\ea37";
  font-family: icomoon;
  top: 0;
  font-size: 11px;
  left: 14px;
  line-height: 30px;
}

.comments ul li .thumb-list .text-holder span {
  display: block;
  color: #555;
  font-size: 14px;
  font-weight: 300;
  line-height: 12px;
  font-family: "Montserrat", sans-serif;
  margin: 0 0 12px 0;
}

.comments ul li .thumb-list .text-holder p {
  color: #555;
  margin: 0;
}

.comments ul li ul.children {
  margin: 0;
  padding: 0 0 0 83px;
}

.comments ul li ul.children li .thumb-list {
  padding: 24px 16px;
  border: 1px solid #e8e9ec;
  background-color: #f5f6f9;
}

.comments input[type="submit"] {
  background-color: #39b44d;
  width: 100%;
  border: none;
  display: block;
  border-radius: 3px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: normal;
  padding: 12px 10px;
  text-transform: uppercase;
  text-align: center;
}

.comments .cs-message label {
  display: block;
}

.comment-navigation {
  padding: 0 15px;
}

.comment-navigation .nav-links {
  margin-bottom: 15px;
}

.comment-navigation .nav-links:after {
  content: "";
  display: table;
  clear: both;
  line-height: 0;
}

.comment-navigation .nav-links .nav-previous {
  display: inline-block;
  margin-right: 10px;
}

.comment-navigation .nav-links .nav-next {
  display: inline-block;
}

.comment-navigation .nav-linksa {
  color: #39b44d;
}

/* Comment Form */

.comment-form {
  margin: 0 0 30px 0;
}

.comment-form .form-holder form .field-holder {
  margin: 0 0 20px 0;
}

.comment-form .form-holder form.row {
  margin: 0 -8px;
}

.comment-form .form-holder form [class*="col-lg"],
.comment-form .form-holder form [class*="col-md"],
.comment-form .form-holder form [class*="col-sm"],
.comment-form .form-holder form [class*="col-xs"] {
  padding: 0 8px;
}

.comment-form .form-holder form .field-holder strong {
  display: block;
  color: #484848;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin: 0 0 4px 0;
}

.comment-form .form-holder form .field-holder label {
  display: block;
  position: relative;
  margin: 0;
}

.comment-form .form-holder form .field-holder label input[type="text"] {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  height: 38px;
  line-height: 38px;
  padding: 10px 20px;
}

.comment-form .form-holder form .field-holder label .button {
  text-decoration: none;
  border: 0;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  padding: 7px 21px;
  text-align: center;
  border-radius: 2px;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  input[type="text"]::-webkit-input-placeholder {
  color: #c0c2cc;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  input[type="text"]:-moz-placeholder {
  color: #c0c2cc;
  opacity: 1;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  input[type="text"]::-moz-placeholder {
  color: #c0c2cc;
  opacity: 1;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  input[type="text"]:-ms-input-placeholder {
  color: #c0c2cc;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  input[type="text"]:focus::-webkit-input-placeholder {
  color: transparent;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  input[type="text"]:focus:-moz-placeholder {
  color: transparent;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  input[type="text"]:focus::-moz-placeholder {
  color: transparent;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  input[type="text"]:focus:-ms-input-placeholder {
  color: transparent;
}

.comment-form .form-holder form .field-holder label textarea {
  line-height: 24px;
  padding: 10px 20px;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  textarea::-webkit-input-placeholder {
  color: #c0c2cc;
}

.comment-form .form-holder form .field-holder label textarea:-moz-placeholder {
  color: #c0c2cc;
  opacity: 1;
}

.comment-form .form-holder form .field-holder label textarea::-moz-placeholder {
  color: #c0c2cc;
  opacity: 1;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  textarea:-ms-input-placeholder {
  color: #c0c2cc;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  textarea:focus:-moz-placeholder {
  color: transparent;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  textarea:focus::-moz-placeholder {
  color: transparent;
}

.comment-form
  .form-holder
  form
  .field-holder
  label
  textarea:focus:-ms-input-placeholder {
  color: transparent;
}

/* Delivery Address start */

.delivery-list {
  padding: 20px;
  margin: 0 0 30px 0;
  border: 1px solid #ebedf3;
  border-radius: 3px;
}

.delivery-list .address-title {
  display: inline-block;
  width: 100%;
  margin: 0 0 5px 0;
}

.delivery-list .address-title h6 {
  color: #2f313a;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0.5px;
  float: left;
  margin: 0;
}

.delivery-list .address-title .short-list {
  float: right;
}

.delivery-list .address-title i {
  color: #d1d3d7;
  font-size: 20px;
  line-height: 16px;
}

.user-dashboard .delivery-list ul {
  margin: 0 0 18px 0;
  padding: 0 40px 0 0;
}

.user-dashboard .delivery-list ul li {
  list-style: none;
  display: block;
  padding: 0 0 0 27px;
  margin: 0 0 8px 0;
  position: relative;
  color: #2f313a;
  font: 400 14px/24px "Roboto", sans-serif;
  letter-spacing: 0.5px;
}

.delivery-list ul li i {
  position: absolute;
  top: 0;
  left: -1px;
  line-height: 24px;
  color: #d1d3d7;
}

.delivery-list .button a {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #b3b6c0;
  font: 400 12px/20px "Roboto", sans-serif;
  text-transform: uppercase;
  border: 1px solid #ebedf3;
  padding: 3px 10px;
  display: inline-block;
  border-radius: 2px;
  margin: 0 10px 0 0;
}

.delivery-list .button a i {
  font-size: 11px;
}

/* Delivery Address End */

/* New foooter style */
/* 
#footer.footer-style-2 .widget.widget_nav_menu ul li a:before,
#footer.footer-style-2 .widget.widget-categories li a:before {
  content: none;
}

#footer.footer-style-2 .widget.widget-categories li a {
  padding: 0;
}

#footer.footer-style-2 .footer-widget .widget ul li span {
  color: #fff !important;
}

#footer.footer-style-2 .widget-connect ul li {
  margin-bottom: 12px;
}

#footer.footer-style-2 .widget-connect ul li span {
  border-radius: 100%;
  height: 40px;
  width: 40px;
}

#footer.footer-style-2 .widget-connect ul li span i {
  color: #fff !important;
}

#footer.footer-style-2 .copy-right {
  border-top: 1px solid #e6e6e6;
}

#footer.footer-style-2 .copy-right .social-media {
  float: right;
}

#footer.footer-style-2 .copy-right .social-media ul {
  margin: 0;
  padding: 0;
}

#footer.footer-style-2 .copy-right .social-media ul li {
  list-style: none;
  display: inline-block;
}

#footer.footer-style-2 .copy-right .social-media a {
  color: #b8bcc9;
  font-size: 20px;
}

/* New foooter style */

#footer {
  background: #282c35;
}

.footer-widget {
  padding: 66px 0 40px;
}
*/

/* News Letter style */
/* 
.footer-widget .widget-newsletter {
  text-align: center;
  border-bottom: 1px solid #22252d;
  padding-bottom: 56px;
  margin-bottom: 39px;
}

.footer-widget .widget-newsletter .fieldset {
  margin: 0 auto;
  width: 700px;
}

#footer .footer-widget .widget-newsletter .widget-title h5 {
  font-size: 20px !important;
  margin-bottom: 0;
  text-transform: uppercase !important;
  color: #adb0b6 !important;
  font-weight: 700 !important;
}

::-moz-placeholder {
  opacity: 1;
}

.footer-widget .widget-newsletter .fieldset form .field-holder {
  width: 77%;
  display: inline-block;
}

.footer-widget
  .widget-newsletter
  .fieldset
  form
  .field-holder
  input[type="text"] {
  color: #b4b4b4;
  height: 47px;
  padding: 0 0 0 20px;
  background: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.footer-widget .widget-newsletter .fieldset form .field-holder.btn-holder {
  display: inline-block;
  width: 20%;
  margin-left: 15px;
}

.footer-widget
  .widget-newsletter
  .fieldset
  form
  .field-holder.btn-holder
  input[type="button"] {
  border: 1px solid #39b44d;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  height: 47px;
  padding: 0 29px;
  text-transform: uppercase;
  background: none;
  border-radius: 4px;
  line-height: 45px;
  background-color: #39b44d;
}

.footer-widget .widget-newsletter form .field-holder label i {
  display: none;
} */

/* News Letter style  */

/* #footer .widget-title {
  margin-bottom: 30px;
  padding: 0;
}

#footer .widget-title h5 {
  font-size: 16px;
  color: #adb0b6;
  margin: 0;
  font-weight: 400;
} */

/* Widget style start */

.widget-text p,
#footer .textwidget {
  font-size: 14px;
  color: #cdcdcd;
  font-weight: 400;
}

.widget-top-cities ul {
  margin: 0;
  padding: 0;
}

.widget-top-cities ul li {
  list-style: none;
  margin-bottom: 5px;
}

.widget-top-cities ul li a {
  font-size: 14px;
  color: #cdcdcd;
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
}

.widget-top-cities ul li a:hover {
  margin-left: 10px;
}

.widget-top-cities ul {
  margin: 0;
  padding: 0;
}

.widget-top-cities ul li {
  list-style: none;
  margin-bottom: 5px;
}

.widget-top-cities ul li a {
  font-size: 14px;
  color: #cdcdcd;
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
}

.widget-top-cities ul li a:hover {
  margin-left: 10px;
}

/* Widget Menu style */

.widget-menu ul {
  margin: 0;
  padding: 0;
}

.widget-menu ul li {
  list-style: none;
  margin-bottom: 5px;
}

.widget-menu ul li a {
  font-size: 14px;
  color: #cdcdcd;
  text-decoration: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
}

.widget-menu ul li a:hover {
  margin-left: 10px;
}

/* Widget Contect style */

.widget-connect ul li {
  position: relative;
  padding-left: 53px;
  margin-bottom: 10px;
  display: block;
}

.widget-connect ul li p {
  margin-bottom: 0px;
  color: #cdcdcd;
  font-size: 14px;
  padding: 6px 0px;
}

.widget-connect ul li p a {
  color: #cdcdcd;
  font-size: 14px;
}

.widget-connect ul li span i {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.widget-connect ul li span {
  color: #fff;
  background: #39b44d;
  border-radius: 4px;
  font-size: 20px;
  width: 34px;
  text-align: center;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}

/*Footer Widget Categories Style Start*/

#footer .widget-categories ul li {
  line-height: 28px;
  padding: 0;
  margin-bottom: 5px;
}

#footer .widget-categories ul li a:before {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#footer .widget.widget_nav_menu ul li a,
#footer .widget.widget-categories li a {
  position: relative;
  padding: 0 0 0 20px;
  margin-bottom: 0;
  border-bottom: 0;
}

/*Footer Widget Categories Style End*/

/* Widget style End */

.copy-right {
  display: block;
  padding: 26px 0 0;
  text-align: center;
  margin-bottom: 60px;
}

.copy-right p {
  color: #9a9a9a;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.copy-right p a {
  color: #39b44d;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
}

foodbakery
  ki
  style.css
  mein
  footer
  k
  style
  k
  baad
  add
  karwin
  isy
  #footer.footer-style-1 {
  background: #333333;
  color: #fff;
}

#footer.footer-style-1 .widget-title h5 {
  color: #fff;
  padding-bottom: 17px;
  position: relative;
}

#footer.footer-style-1 .widget-title h5:after {
  content: "";
  display: block;
  background: #404040;
  height: 1px;
  width: 27px;
  background: #dc6d34;
  position: absolute;
  bottom: -1px;
}

#footer.footer-style-1 .widget-title:after {
  content: "";
  display: block;
  background: #404040;
  height: 1px;
}

/* Footer Social style Start */

#footer.footer-style-1 .copyright-sec .copyright-inner {
  border-top: 1px solid rgba(137, 137, 137, 0.2);
  padding: 30px 0 10px;
}

.footer-style-1 .social-media {
  margin: 0 0 10px;
}

.footer-style-1 .social-media li {
  display: inline-block;
  padding: 0;
  margin: 0 5px 10px 0;
}

.footer-style-1 .social-media li a {
  background-color: #999;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  height: 25px;
  line-height: 28px;
  text-align: center;
  width: 25px;
}

.footer-style-1 .social-media li a:hover {
  color: #fff !important;
}

#footer.footer-style-1 .footer-widget .social-media li a i {
  color: #333 !important;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#footer.footer-style-1 .footer-widget .social-media li a:hover i {
  color: #fff !important;
}

.footer-style-1 .copy-right {
  display: inline-block;
  padding: 0;
  margin: 0 0 20px;
}

.footer-style-1 .copy-right p {
  line-height: normal;
  margin: 0;
}

.footer-style-1 .right-logos {
  float: right;
}

.footer-style-1 .right-logos p {
  margin: 0;
}

/* Footer Social style End */

/* Footer style End  */

/* begin style home page */

.categories-holder {
  background-color: #fff;
  padding: 32px 0 0;
  border: 1px solid #ced3d9;
  text-align: center;
  margin-bottom: 60px;
}

.categories-holder .categories-list {
  margin-bottom: 30px;
}

.categories-holder .categories-list i {
  font-size: 40px;
  margin-bottom: 15px;
  display: block;
}

.categories-holder .categories-list img {
  margin-bottom: 15px;
}

.categories-holder .categories-list h6,
.categories-holder .categories-list h6 a {
  text-transform: uppercase !important;
  font-size: 12px !important;
  color: #333333 !important;
}

.element-title h3 {
  margin: 0;
}

.element-title p {
  color: #bbbbbb;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.location-holder {
  background-color: #fff;
  padding: 22px 13px 0;
  border: 1px solid #ced3d9;
  text-align: left;
}

.location-holder .more-btn-holder {
  padding-top: 8px;
  text-align: center;
}

.location-holder a.more-btn {
  display: inline-block;
  padding: 9px 25px;
  margin: 0 0 34px 0;
  outline: none;
  text-decoration: none;
  color: #fff;
  background-color: #39b44d;
  border-radius: 40px;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}

.location-holder ul.location-list {
  padding: 0;
  margin: 0 0 24px;
}

.location-holder ul.location-list li {
  list-style: none;
  padding: 0;
  margin: 0 0 7px;
  display: block;
  color: #333333;
  text-align: left;
}

.location-holder ul.location-list li:last-child {
  margin-bottom: 0;
}

.location-holder ul.location-list li span {
  font-size: 14px;
  color: #89959b;
  margin-left: 10px;
}

/*testimonial style start*/

/*location classic style start*/

.location-holder.classic {
  border: 0;
  padding: 0;
  background: none;
}

.location-holder.classic ul.location-list {
  background: #f2f2f2;
  padding: 30px;
  border-radius: 20px 0px 20px 0px;
  /*height: 231px;*/
}

.location-holder.classic ul.location-list li a {
  text-transform: uppercase;
}

.location-holder.classic ul.location-list li span {
  font-weight: 600;
  color: #e51a1b;
  text-transform: uppercase;
}

.location-holder.classic a.more-btn {
  background: none;
  color: #39b44d;
  border: 1px solid #39b44d;
  padding: 9px 40px;
  border-radius: 4px;
  text-transform: capitalize;
}

.location-holder.classic .more-btn-holder {
  padding-top: 40px;
}

.location-holder.classic [class*="col-lg"] {
  position: relative;
}

.location-holder.classic [class*="col-lg"]:after {
  position: absolute;
  top: 0;
  right: 1px;
  width: 1px;
  height: 85%;
  background: #e7e7e7;
  content: "";
}

.location-holder.classic [class*="col-lg"]:nth-child(4):after,
.location-holder.classic [class*="col-lg"]:nth-child(5):after {
  display: none;
}

/*location classic style start*/

/*location modern style start*/

.location-holder.modern {
  position: relative;
  border: 0;
  background: none;
  padding: 0;
}

.location-holder.modern ul.location-list {
  padding: 25px;
  background: #f2f2f2;
  border-radius: 15px 0px 15px 0px;
  margin: 0;
}

.location-holder.modern ul.location-list:after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  background: #e7e7e7;
  content: "";
}

.location-holder.modern ul.location-list li {
  margin-bottom: 4px;
}

.location-holder.modern ul.location-list li a {
  font-size: 14px;
  text-transform: uppercase;
}

.location-holder.modern [class*="col-"]:first-child ul.location-list:after {
  background: none;
}

.location-holder.modern .more-btn-holder {
  padding-top: 60px;
}

.location-holder.modern a.more-btn {
  border-radius: 4px;
  background: none;
  border: 1px solid #39b44d;
  font-size: 14px;
  font-weight: normal;
  padding: 9px 30px;
  text-transform: capitalize;
  margin-bottom: 0;
}

.location-holder.modern ul.location-list li span {
  color: #e51a1b;
  text-transform: uppercase;
}

/*location modern style End*/

.section-title.aligncenter h3 {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.testimonial .img-holder {
  float: left;
  margin-right: 25px;
  width: 50px;
}

.testimonial .img-holder img {
  width: 100%;
  border-radius: 50%;
}

.testimonial .text-holder {
  overflow: hidden;
}

.testimonial .text-holder p {
  font-size: 13px;
  color: #999;
  letter-spacing: 1px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.testimonial .text-holder .author-detail .auther-name span {
  font-size: 14px;
  color: #333333;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}

.main-post .img-frame {
  text-align: right;
  display: inline-block;
  vertical-align: middle;
  width: 39%;
  margin-right: 55px;
}

.main-post .column-text {
  display: inline-block;
  vertical-align: middle;
  width: 55%;
}

.main-post .column-text h3 {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 5px;
  letter-spacing: 0;
}

.main-post .column-text .app-btn {
  display: inline-block;
  margin-bottom: 25px;
  padding-right: 10px;
}

.main-post .column-text .app-btn img {
  border-radius: 6px;
}

.main-post .column-text form .field-holder {
  width: 340px;
  position: relative;
}

.main-post .column-text form .field-holder .field-input {
  color: #999999;
  font-size: 14px;
  height: 41px;
  border-color: #cccccc;
  padding-right: 120px;
}

.main-post .column-text form .field-holder .field-label-btn {
  position: absolute;
  right: 1px;
  top: 1px;
  height: 41px;
}

.main-post .column-text form .field-holder .field-btn {
  height: 39px;
  border: none;
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  line-height: normal;
}

.main-post.promo-box .column-text .promo-content {
  line-height: 24px;
  margin-bottom: 20px;
}

/*testimonial style end*/

/* counter style */

.counter-sec {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}

.counter-holder .text-holder i {
  color: #4cce4a;
  font-size: 22px;
  margin-right: 15px;
  vertical-align: top;
  display: inline-block;
}

.counter-holder .text-holder strong {
  color: #505050;
  font-size: 22px;
  margin-right: 10px;
  display: inline-block;
}

.counter-holder .text-holder span {
  font-size: 18px;
  color: #505050;
  letter-spacing: 1px;
  display: inline-block;
}

/* counter style */

/* end style home page */

/*Listing Time tooltip*/

.post-time {
  position: relative;
}

.post-time .time-tooltip {
  position: absolute;
  bottom: 0;
  left: 40%;
  width: 250px;
  height: auto;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translate(-50%, -20px);
  -moz-transform: translate(-50%, -20px);
  -ms-transform: translate(-50%, -20px);
  transform: translate(-50%, -20px);
  text-align: center;
  padding: 10px;
  z-index: 4;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 32px;
}

.post-time .time-tooltip:after {
  content: "";
  position: absolute;
  width: 19px;
  height: 19px;
  background: #fff;
  -webkit-transform: rotate(45deg) translateX(-50%);
  -moz-transform: rotate(45deg) translateX(-50%);
  -ms-transform: rotate(45deg) translateX(-50%);
  transform: rotate(45deg) translateX(-50%);
  bottom: -12px;
  left: 50%;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.post-time .time-tooltip:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #fff;
  border-radius: 10px;
}

.post-time:hover .time-tooltip {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-50%, -10px);
  -moz-transform: translate(-50%, -10px);
  -ms-transform: translate(-50%, -10px);
  transform: translate(-50%, -10px);
}

.time-tooltip-holder {
  position: relative;
  z-index: 1;
}

.time-tooltip .tooltip-label {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  color: #2f313a;
}

.time-tooltip .tooltip-info {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  font-weight: 400;
}

.sticky-sidebar {
  margin-bottom: 20px;
}

/* Loader Style End */

.icon-spinner,
.icon-spinner8.fa-spin,
.fa-spin,
.icon-spin,
.icon-spinner8 {
  -webkit-animation: fa-spin 2s infinite linear;
  -moz-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.icon-spinner8.fa-spin,
.icon-spin,
.icon-spinner8,
.icon-spinner {
  position: absolute;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@-moz-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/*All Result Style Loader Line Start*/

@-webkit-keyframes fullexpand {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@-moz-keyframes fullexpand {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes fullexpand {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes fullexpand {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes fullexpand {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/*All Result Style Loader Line End*/

/* Loader Style End */

/* Separator Style */

.login-form .separator {
  margin: 0 0 20px;
  overflow: hidden;
  text-align: center;
}

.login-form .separator span {
  color: #a3a7ab;
  font-size: 14px;
  position: relative;
  text-transform: uppercase;
}

.login-form .separator span:before,
.login-form .separator span:after {
  background-color: #ebebeb;
  bottom: 8px;
  content: "";
  height: 1px;
  left: 100%;
  margin: 0 0 0 10px;
  position: absolute;
  width: 9999px;
}

.login-form .separator span:after {
  left: auto;
  margin: 0 10px 0 0;
  right: 100%;
}

.login-form #growls.default {
  top: -20px;
  /*left: 14px;position: absolute;right: auto;bottom: auto;*/
}

/* Popup Profile Checked Style Start*/

.input-filed:after {
  clear: both;
  display: block;
  content: "";
}

.input-filed .input-sec {
  margin: 0 0 15px 0;
  width: 50%;
  float: left;
}

.input-filed .input-sec input {
  display: none;
}

.input-filed .input-sec label {
  border: 1px solid #ccc;
  cursor: pointer;
  font-size: 14px;
  margin: 0 5px 0 0;
  padding: 6px 15px;
  text-align: center;
  width: 100%;
}

.input-filed .input-sec input:checked + label {
  background-color: #2b8dc4;
  color: #fff;
  border-color: #2b8dc4;
}

/*Loader login form Start*/

.login-form {
  position: relative;
}

.login-form .foodbakery_loader {
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.login-form .foodbakery_loader .loader-img i {
  font-size: 35px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: -20px;
}

#growls.foodbakery_element_growl {
  position: relative;
}

#growls.foodbakery_element_growl .growl {
  right: 0;
  margin: 0 10px 0;
  background: none;
  color: #3c763d;
  padding-left: 0;
  padding-bottom: 0;
}

#growls.foodbakery_element_growl .growl .growl-close {
  display: none;
}

#growls.foodbakery_element_growl .growl.growl-error {
  color: #a94442;
}

/*#growls Style Notification Start*/

#growls {
  z-index: 50000;
  position: fixed;
}

#growls.default {
  bottom: 10px;
  right: 10px;
}

#growls.tl {
  top: 10px;
  left: 10px;
}

#growls.tr {
  top: 10px;
  right: 10px;
}

#growls.bl {
  bottom: 10px;
  left: 10px;
}

#growls.br {
  bottom: 10px;
  right: 10px;
}

#growls.tc {
  top: 10px;
  right: 10px;
  left: 10px;
}

#growls.bc {
  bottom: 10px;
  right: 10px;
  left: 10px;
}

#growls.tc .growl,
#growls.bc .growl {
  margin-left: auto;
  margin-right: auto;
}

.growl {
  opacity: 0.8;
  filter: alpha(opacity=80);
  position: relative;
  border-radius: 4px;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.growl.growl-incoming {
  opacity: 0;
  filter: alpha(opacity=0);
}

.growl.growl-outgoing {
  opacity: 0;
  filter: alpha(opacity=0);
}

.growl.growl-small {
  min-width: 200px;
  padding: 5px;
  margin: 5px;
}

.growl.growl-medium {
  min-width: 250px;
  padding: 10px;
  margin: 10px;
}

.growl.growl-large {
  min-width: 300px;
  padding: 15px;
  margin: 15px;
}

.growl.growl-default {
  color: #fff;
  background: #7f8c8d;
}

.growl.growl-error {
  color: #fff;
  background: #c0392b;
}

.growl.growl-notice {
  color: #fff;
  background: #2ecc71;
}

.growl.growl-warning {
  color: #fff;
  background: #f39c12;
}

.growl.growl-success {
  color: #fff;
  background: #2ecc71;
}

.growl .growl-close {
  cursor: pointer;
  float: right;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  font-family: helvetica, verdana, sans-serif;
}

.growl .growl-title {
  font-size: 18px;
  line-height: 24px;
}

.growl .growl-message {
  font-size: 14px;
  line-height: 18px;
}

/*#growls Style Notification End*/

/*Button Style*/

.cs-button {
  font-weight: bold;
}

.cs-button.btn-left {
  text-align: left;
}

.cs-button.btn-right {
  text-align: right;
}

.cs-button.btn-center {
  text-align: center;
}

.custom-btn {
  text-align: center;
}

.button_style .custom-btn {
  vertical-align: bottom;
}

.button_style.center {
  text-align: center;
}

.button_style.right {
  text-align: right;
}

.custom-btn {
  padding: 0px 15px;
  display: inline-block;
  position: relative;
}

.custom-btn.button-icon-right i {
  float: right;
  line-height: 38px;
  margin-left: 10px;
}

.custom-btn.button-icon-left i {
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

.custom-btn.no_circle {
  border-radius: 2px;
}

.custom-btn.circle {
  border-radius: 50px;
}

.custom-btn.large-btn {
  line-height: 44px;
  min-width: 150px;
}

.custom-btn.medium-btn {
  line-height: 35px;
  min-width: 115px;
}

.custom-btn.small-btn {
  line-height: 30px;
  min-width: 90px;
}

.custom-btn.csborder-color {
  border: 1px solid;
}

.custom-btn.medium-btn i {
  line-height: 33px;
}

.custom-btn.small-btn i {
  font-size: 14px;
  line-height: 28px;
}

.bypostauthor {
  overflow: hidden;
}

/*Button Style*/

/*Register Form Style Start*/

.field-holder {
  position: relative;
}

.signup-form .field-holder label,
.signup-form .field-holder label {
  color: #484848;
  display: block;
  font-size: 14px;
  line-height: normal;
  margin: 0 0 10px;
  text-transform: uppercase;
  font-family: "Karla", sans-serif;
}

.signup-form .field-holder input[type="text"],
.signup-form .field-holder textarea,
.invite-member .field-holder input[type="text"],
.signup-form .field-holder input[type="password"],
.signup-form .field-holder input[type="text"],
.signup-form .field-holder input[type="password"],
.newsletter .field-holder input[type="text"],
.newsletter .field-holder textarea,
.newsletter .field-holder input[type="password"] {
  background-color: #fff;
  /* border: 1px solid #f4f4f4;
    
    color: #919191;
    font-size: 14px; 
    height: 34px;
    padding: 0 12px;
    */
  margin: 0 0 15px;
  border-radius: 3px;
}

.signup-form .field-holder input[type="email"] {
  border-radius: 3px;
  margin: 0 0 15px;
  /*border: 1px solid #f4f4f4; color: #919191;
    font-size: 14px;
    height: 34px; padding: 0 12px; */
}

.signup-form
  .field-holder
  .chosen-container-single
  .chosen-search
  input[type="text"] {
  margin: 0;
  height: 28px;
}

.signup-form .field-holder input[type="text"]::-webkit-input-placeholder,
.signup-form .field-holder textarea::-webkit-input-placeholder {
  opacity: 1;
}

.signup-form .field-holder input[type="text"]:-moz-placeholder,
.signup-form .field-holder textarea:-moz-placeholder {
  opacity: 1;
}

.signup-form .field-holder input[type="text"]::-moz-placeholder,
.signup-form .field-holder textarea::-moz-placeholder {
  opacity: 1;
}

.signup-form .field-holder input[type="text"]:-ms-input-placeholder,
.user-holder .field-holder textarea:-ms-input-placeholder {
  opacity: 1;
}

.signup-form .field-holder img {
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.signup-form .field-holder textarea {
  height: 102px;
  padding-top: 10px;
}

.signup-form {
  max-width: 600px;
  margin: 0 auto 40px auto;
  background: #f5f5f5;
  padding: 40px 30px;
}

.signup-form.signup-with-orders {
  width: 100%;
}

.signup-form .user-submit {
  background-color: #3db44d;
  border: none;
  border-radius: 3px;
  color: #f5f5f5;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  padding: 10px 15px;
  text-transform: uppercase;
  margin-right: 10px;
}

.signup-form .login-section {
  float: right;
  text-transform: capitalize;
}

.signup-form .login-section a {
  text-decoration: underline;
  color: #3db44d;
}

.signup-form .alert-warning {
  padding: 8px 15px;
  font-size: 13px;
}

.signup-form > .triggered-box {
  opacity: 0;
  height: 0;
}

.signup-form > .triggered-box.activate {
  opacity: 1;
  height: auto;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

/*Register Form Style End*/

/*Location Account Setting Style Start*/

/*On Off Switch Style*/

.cs-map-section p {
  margin-top: 16px;
}

.cs-map-section {
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.switchs-holder2 {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0 0 15px 0;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.switchs-holder2 .acc-submit {
  border: none;
  display: inline-block;
  font-size: 14px;
  margin-top: 10px;
  padding: 5px 16px;
  line-height: normal;
  border-radius: 4px;
  color: #ffffff;
}

.switchs-holder2 > span {
  color: #4a4a4a;
  display: inline-block;
  font-size: 13px;
  margin: 0 -4px 0 0;
  height: 22px;
  line-height: 16px;
  vertical-align: middle;
  width: 60%;
  padding: 0;
  text-decoration: none;
  text-transform: none;
}

.switchs-holder2 .right-area {
  display: inline-block;
  margin: 0 -4px 0 0;
  vertical-align: middle;
  width: 40%;
}

.cs-map-section .hide_location {
  position: absolute;
  z-index: 30;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  padding: 6px 20px;
}

.cs-map-section .hide_location .right-area {
  float: right;
}

.user-holder .field-holder .switchs-holder2 label {
  margin: 0;
}

.onoffswitch {
  margin: 0 0 10px;
  float: right;
  position: relative;
  width: 65px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.onoffswitch.onoffswitch-v2 {
  margin: 0;
}

input[type="checkbox"].onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50px;
  margin-bottom: 0;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -webkit-transition: margin 0.3s ease-in 0s;
  -moz-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: white;
  display: block;
  float: left;
  font-size: 14px;
  font-weight: bold;
  height: 26px;
  line-height: 26px;
  padding: 0;
  width: 50%;
}

.onoffswitch-inner:before {
  background-color: #4bd865;
  color: #fff;
  content: "ON";
  font-size: 14px;
  padding-left: 10px;
  padding-right: 0;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 7px;
  background-color: #eee;
  color: #999;
  text-align: right;
  font-size: 14px;
}

.onoffswitch-switch {
  background-color: #fff;
  border-radius: 50px;
  bottom: 0;
  display: block;
  height: 22px;
  margin: 2px 4px 2px 0;
  position: absolute;
  right: 34px;
  top: 0;
  -webkit-transition: all 0.3s ease-in 0s;
  -moz-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  width: 22px;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/*Location Account Setting Style End*/

.user-info {
  margin: 0;
  padding: 25px 0 35px 0;
}

.user-info:after {
  clear: both;
  content: "";
  display: block;
}

.user-info .img-holder {
  float: left;
  width: 70px;
  height: auto;
  margin: 0 25px 0 0;
}

.user-info .img-holder figure {
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #dbdfe2;
}

.user-info .img-holder figure img {
  height: auto;
  width: 100%;
}

.user-info .text-holder {
  overflow: hidden;
  display: block;
  position: relative;
  padding: 0 120px 0 0;
}

.user-info .text-holder span {
  font-size: 14px;
  line-height: 18px;
  color: #a3a7ab;
  display: block;
}

.user-info .text-holder h3 {
  margin: 0 0 0 0;
  line-height: 28px;
}

.user-info .text-holder p {
  color: #a3a7ab;
  letter-spacing: 1px;
  margin: 0;
  line-height: 24px;
}

.alert {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  text-align: left;
  color: #fff;
  padding: 2px 6px;
  border-radius: 3px;
}

.footer-widget .widget-newsletter .fieldset {
  position: relative;
}

.footer-widget .widget-newsletter .fieldset .alert {
  position: absolute;
  bottom: -50px;
  left: 1%;
  width: 99%;
}

.footer-widget .widget-newsletter .fieldset .alert .close,
#footer .footer-widget p span {
  color: #fff;
  opacity: 1;
}

/*404 Page Style Start*/

.page-not-found {
  background-color: #fff;
  margin: 50px auto;
  padding: 50px;
  text-align: center;
  width: 600px;
}

.page-not-found .cs-text {
  margin: 0 0 20px;
}

.page-not-found .cs-text span {
  font-size: 25px;
  font-weight: 500;
  text-transform: uppercase;
}

.page-not-found .cs-text span.cs-error {
  color: #2b8dc4;
  font-size: 40px;
  font-weight: 700;
}

.page-not-found .cs-text p {
  color: #555;
  font-size: 16px;
  padding-top: 10px;
}

.page-not-found form .input-holder {
  position: relative;
}

.page-not-found form i {
  color: #555;
  font-weight: 700;
  left: 10px;
  position: absolute;
  top: 11px;
}

.page-not-found form input[type="text"] {
  border-color: #ccc;
  color: #333;
  font-size: 13px;
  height: 36px;
  padding: 0 100px 0 30px;
  line-height: 36px;
}

.page-not-found form input[type="submit"] {
  border: none;
  color: #fff;
  border-radius: 0 4px 4px 0;
  font-size: 13px;
  height: 36px;
  padding: 0 18px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 0;
}

/*404 Page Style End*/

.no-results .suggestions ul {
  margin: 0px 0 20px 0;
  padding: 0px;
}

.no-results .suggestions ul li {
  list-style: none;
  font-size: 14px;
  line-height: 28px;
  padding-left: 20px;
  position: relative;
}

.no-results .suggestions ul li:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 14px;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: #000;
}

.no-results .search-form .search-submit {
  border: 0;
  height: 54px;
  padding: 0 12px;
  background: #2b8dc4;
  color: #fff;
}

.no-results .search-form span.screen-reader-text {
  display: none;
}

.no-results .search-form .search-submit {
  border: 0;
  height: 54px;
  padding: 0 12px;
  background: #2b8dc4;
  color: #fff;
}

.fluid-width-video-wrapper {
  border: 5px solid #fff;
}

.custom-video-holder .fluid-width-video-wrapper {
  border: 0;
}

.comment-form .section-title {
  margin: 0 0 20px 0;
}

/* Contect Info style */

.contact-info .element-title h4,
.contact-info .element-title h5,
.contact-info .element-title h3 {
  margin-bottom: 0px;
  /* text-transform: uppercase !important; */
  color: #3db44d !important;
}

.contact-info .element-title {
  margin-bottom: 16px;
}

.contact-info {
  margin-bottom: 40px;
}

.contact-info ul {
  padding: 0px;
  margin: 0px;
}

.contact-info ul li {
  list-style: none;
  padding: 0px;
  margin: 0px 0 20px 0;
}

.contact-info ul li .text-holder {
  display: inline-block;
  margin: 0px 46px 0 0;
}

.contact-info ul li .text-holder .title {
  display: block;
  color: #2f313a;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 6px;
  letter-spacing: 1px;
  line-height: 20px;
}

.contact-info ul li .text-holder strong {
  display: block;
  font-size: 15px;
  color: #555;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 1px;
}

/* Contect Info style */

/* Contect Form style */

.contact-form .element-title h4,
.contact-form .element-title h5,
.contact-form .element-title h3 {
  margin-bottom: 0px;
  color: #3db44d !important;
}

.contact-form .element-title {
  margin-bottom: 20px;
}

.contact-form {
  margin-bottom: 40px;
}

.contact-form .field-holder {
  margin-bottom: 22px;
}

.contact-form .field-holder strong {
  font-size: 14px;
  color: #484848;
  font-weight: 400;
  display: block;
  line-height: 18px;
  margin-bottom: 10px;
}

.contact-form .field-holder .field-input,
.contact-form .field-holder textarea {
  height: 44px;
  background-color: #fff;
  line-height: 44px;
  border: 1px solid #e5e7eb;
  padding-left: 13px;
  color: #555;
  font-size: 14px;
  font-weight: 400;
}

.contact-form .field-holder textarea {
  height: 165px;
  line-height: 24px;
  padding-left: 12px;
  width: 100%;
  resize: none;
}

.contact-form
  .field-holder
  .checkbox-area
  input[type="checkbox"]:checked
  + label::before {
  color: #999999;
  content: "î¦¯";
  font-family: "icomoon";
  font-size: 14px;
  line-height: 15px;
}

.contact-form .field-holder .checkbox-area input[type="checkbox"] {
  display: none;
}

.contact-form .field-holder .checkbox-area label {
  font-size: 14px;
  color: #555;
  font-weight: 400;
  padding-left: 24px;
  position: relative;
  line-height: 24px;
  margin-bottom: 0px;
}

.contact-form .field-holder .checkbox-area label::before {
  background: transparent;
  border: 1px solid #d1d3d4;
  content: "";
  display: inline-block;
  height: 16px;
  left: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 5px;
  width: 16px;
  border-radius: 2px;
}

.contact-form .input-btn input[type="submit"] {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  border: none;
  height: 44px;
  line-height: 44px;
  text-align: center;
  display: inline-block;
  padding: 0px 22px;
}

/* Contect Form style */

/* under-construction style start*/

#cs-construction {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

#cs-construction:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  left: 0;
  top: 100%;
  z-index: -1;
}

.cs-construction {
  text-align: center;
  background: #fff;
  padding: 40px 35px 47px;
  border: 1px solid #d4d4d4;
}

.cs-construction .cs-logo .cs-media {
  margin-bottom: 25px;
}

.cs-construction > span {
  margin-bottom: 20px;
  display: block;
  text-transform: uppercase;
  font-size: 20px;
}

.cs-construction-holder {
  width: 100%;
  text-align: center;
}

.cs-construction-holder [class*="col-lg"],
.cs-construction-holder [class*="col-md"],
.cs-construction-holder [class*="col-sm"],
.cs-construction-holder [class*="col-xs"] {
  float: none;
  margin: 0 auto;
}

.cs-const-counter {
  margin-bottom: 3px;
}

.cs-construction .time-box {
  display: inline-block;
  padding: 0 5px;
  position: relative;
  margin-right: 10px;
}

.cs-construction .time-box:last-child {
  margin-right: 0;
}

.cs-construction .time-box:last-child:after {
  display: none;
}

.cs-construction .time-box:after {
  height: 10px;
  width: 3px;
  content: "";
  background: #f2f2f2;
  position: absolute;
  top: 15px;
  right: -5px;
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.cs-construction .time-box:first-child {
  margin-left: 0;
}

.cs-construction .time-box h4 {
  font-weight: normal;
  margin-bottom: 0;
  padding-bottom: 0;
  width: 30px;
}

.cs-construction .time-box:first-child h4 {
  width: 46px;
}

.cs-construction .time-box .label {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: normal;
  color: #c1c1c1;
  text-align: center;
  -webkit-transform: translateX(10%);
  -moz-transform: translateX(10%);
  -ms-transform: translateX(10%);
  transform: translateX(10%);
}

.cs-seprater {
  width: 595px;
  height: 17px;
  background: #f2f2f2;
  position: relative;
  margin-bottom: 30px;
  display: inline-block;
}

.cs-seprater:after {
  background: #2377af;
  width: 50px;
  height: 17px;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
}

.center .cs-seprater,
.left .cs-seprater,
.right .cs-seprater {
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  position: relative;
  margin-bottom: 30px;
  display: inline-block;
}

.center .cs-seprater:after,
.left .cs-seprater:after,
.right .cs-seprater:after {
  background: #2377af;
  width: 50px;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
  content: "";
}

.center .cs-seprater:after {
  margin: 0 0 0 -25px;
  left: 50%;
}

.right .cs-seprater:after {
  right: 0;
  left: auto;
}

.center .cs-seprater,
.left .cs-seprater,
.right .cs-seprater {
  margin-top: 30px;
}

.cs-spreator.fancy .cs-seprater {
  background: none;
  height: auto;
  margin: 0;
}

.cs-spreator.fancy .cs-seprater span {
  display: inline-block;
}

.cs-spreator.fancy .cs-seprater span img {
  display: block;
}

.cs-spreator.fancy .cs-seprater:after {
  display: none;
}

.cs-spreator.modern_new .cs-seprater {
  background-color: inherit;
  margin-top: 0;
  height: auto;
  margin-bottom: 5px;
}

.center .cs-spreator.modern_new .cs-seprater:after {
  display: none;
}

.cs-construction .input-holder {
  width: 456px;
  display: inline-block;
  margin-bottom: 30px;
}

.cs-construction .input-holder input[type="text"] {
  color: #808080;
  font-size: 13px;
  text-transform: uppercase;
  height: 42px;
}

.cs-construction .input-holder {
  position: relative;
}

.cs-construction .input-holder input[type="submit"] {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  padding: 0 12px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 41px;
  border-radius: 0;
}

.cs-construction .input-holder i {
  position: absolute;
  left: 14px;
  top: 12px;
  font-size: 18px;
  color: #c9c9c9;
}

.cs-construction .cs-news-letter {
  width: 567px;
  margin: 0 auto;
}

.cs-construction .news-letter-heading {
  float: left;
}

.cs-construction .news-letter-heading h6,
.cs-construction .news-letter-heading label {
  padding-right: 10px;
  color: #828282;
}

.news-letter-form {
  float: left;
}

.cs-construction .cs-social-media {
  width: 100%;
  overflow: hidden;
}

.cs-construction .cs-social-media ul {
  margin: 0;
  padding: 0;
}

.cs-construction .cs-social-media li {
  list-style: none;
  display: inline-block;
  margin-right: 6px;
}

.cs-construction .cs-social-media li:last-child {
  margin-right: 0;
}

.cs-construction .cs-social-media li a {
  text-decoration: none;
  outline: none;
  display: inline-block;
  text-align: center;
  color: #fff;
  border: 0;
}

.cs-construction .cs-social-media li a i {
  font-size: 11px;
}

.cs-construction .cs-social-media li a[data-original-title="rss"] i {
  color: #f06c19;
}

.cs-construction .cs-social-media li a[data-original-title="facebook"] i {
  color: #2b4a8b;
}

.cs-construction .cs-social-media li a[data-original-title="twitter"] i {
  color: #40bff5;
}

.cs-construction .cs-social-media li a[data-original-title="linkedin"] i {
  color: #10598c;
}

.cs-construction .cs-social-media li a[data-original-title="google"] i {
  color: #d83936;
}

.cs-construction .cs-social-media li a[data-original-title="youtube"] i {
  color: #da2327;
}

.cs-construction .news-letter-heading h6,
.cs-construction .news-letter-heading label {
  line-height: 42px !important;
  text-transform: uppercase !important;
}

.cs-construction .news-letter-heading h6,
.cs-construction .news-letter-heading label {
  line-height: 42px !important;
  text-transform: uppercase !important;
}

.cs-construction .time-box .label {
  display: inline-block !important;
}

.cs-construction .time-box h4 {
  color: #207dba !important;
  font-size: 26px !important;
  display: inline-block !important;
}

/* under-construction style end*/

/*Author List Detail Start*/

.author-list-holder {
  margin: 0 0 15px;
}

.author-list-holder:after {
  clear: both;
  display: block;
  content: "";
}

.author-list-holder figure {
  float: left;
  margin: 0 10px 0 0;
  width: 60px;
}

.author-list-holder figure img {
  border-radius: 100%;
  width: 100%;
  height: auto;
}

.author-list-holder .text-holder {
  overflow: hidden;
}

.author-list-holder p {
  display: inline-block;
  line-height: normal;
  vertical-align: top;
  margin: 0;
  font-size: 14px;
  color: #2f313a;
  font-weight: 300;
}

.author-list-holder .name {
  font-weight: 700;
  color: #39b44d;
  display: block;
  padding: 3px 0 0;
  font-size: 16px;
}

/*Author List Detail End*/

.alert-danger {
  border-color: #39b44d;
}

section.no-results.not-found header.page-header {
  margin-top: 0;
}

section.no-results.not-found {
  padding: 30px;
}

/* Safari only override */

::i-block-chrome,
.field-holder textarea,
::i-block-chrome,
.field-holder input.field-input[type="date"],
::i-block-chrome,
.field-holder input[type="datetime"],
::i-block-chrome,
.field-holder input[type="datetime-local"],
::i-block-chrome,
.field-holder input[type="email"],
::i-block-chrome,
.field-holder input[type="month"],
::i-block-chrome,
.field-holder input[type="number"],
::i-block-chrome,
.field-holder input[type="password"],
::i-block-chrome,
.field-holder input[type="range"],
::i-block-chrome,
.field-holder input[type="search"],
::i-block-chrome,
.field-holder input[type="tel"],
::i-block-chrome,
.field-holder input[type="text"],
::i-block-chrome,
.field-holder input[type="time"],
::i-block-chrome,
.field-holder input[type="url"],
::i-block-chrome,
.field-holder input[type="week"] {
  line-height: normal !important;
}

::i-block-chrome,
textarea {
  padding-top: 15px !important;
  line-height: normal !important;
}

.activation-tab-message {
  text-align: center;
  margin-top: 70px;
}

.activation-tab-message:after {
  content: "";
  display: table;
  line-height: 0;
  clear: both;
}

.activation-tab-message .text-holder strong {
  color: #31cd75;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 45px;
  display: block;
  margin-bottom: 15px;
}

.activation-tab-message .text-holder span {
  color: #999999;
  display: block;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.activation-tab-message .media-holder {
  margin-bottom: 25px;
}

.activation-tab-message .text-holder span a {
  color: #4a95d2;
}

.activation-tab-message .thankyou-contacts {
  text-align: center;
}

.activation-tab-message .text-holder {
  max-width: 80%;
  margin: 0 auto 70px auto;
}

.user-holder .thankyou-contacts {
  margin-bottom: 40px;
}

.user-holder .thankyou-contacts .list-inline li {
  float: none;
  margin-right: 25px;
}

.thankyou-contacts li i {
  margin-right: 5px;
  color: #888888;
  font-size: 14px;
  font-size: 18px;
  vertical-align: middle;
}

.activation-tab-message .thankyou-contacts > p {
  color: #484848 !important;
  font-size: 13px;
  margin-bottom: 15px;
  line-height: 18px;
}

/*Short Code Start*/

/* Element Title Style Start*/

.element-title {
  margin-bottom: 15px;
}

.element-title h2 {
  margin-bottom: 0px;
}

/*Accordion Style Start*/

.panel-group.simple.simple {
  margin-bottom: 30px;
}

.panel-group.simple .panel {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 3px;
}

.panel-group.simple .panel-default > .panel-heading {
  background-image: none;
  background-color: #fff;
  padding: 0px;
}

.panel-group.simple .panel-default .panel-title a i {
  display: inline-block;
  margin-right: 5px;
}

.panel-group.simple .panel-default .panel-title a {
  display: block;
  padding: 10px 15px;
}

/*Modern Style Start*/

.panel-group.modern .panel {
  border-radius: 0px;
}

.panel-group.modern .panel-default > .panel-heading {
  border-color: rgba(0, 0, 0, 0.8);
}

.panel-group.modern .panel + .panel {
  margin-top: -1px;
}

.panel-group.modern .panel-heading {
  padding: 12px 20px;
}

.panel-group.modern .panel-default > .panel-heading {
  background-image: none;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0px;
  border-radius: 0px;
}

.panel-group.modern .panel-default .panel-title a i {
  display: inline-block;
  margin-right: 5px;
}

.panel-group.modern .panel-default .panel-title a {
  display: block;
  padding: 10px 25px 10px 15px;
  position: relative;
}

.faq.panel-group .panel-heading a i {
  display: inline-block;
  margin-right: 5px;
}

.panel-group.modern .panel-default .panel-title a.collapsed:after {
  content: "\f067";
  position: absolute;
  right: 15px;
  top: 11px;
  font-family: "icomoon";
  font-size: 14px;
  color: #666;
}

.panel-group.modern .panel-default .panel-title a:after {
  content: "\f068";
  position: absolute;
  right: 15px;
  top: 11px;
  font-family: "icomoon";
  font-size: 14px;
  color: #666;
}

/*Accordion Style End*/

/*Call To Action Style Start*/

.cs-calltoaction {
  padding: 30px;
  margin-bottom: 30px;
}

.cs-calltoaction .align-center {
  text-align: center;
}

.cs-calltoaction .cs-text h2 {
  margin-bottom: 0px;
}

.cs-calltoaction .align-left {
  text-align: left;
}

.cs-calltoaction .align-right {
  text-align: right;
}

.cs-calltoaction .cs-text {
  display: inline-block;
  width: 70%;
  margin-right: 20px;
}

.cs-calltoaction .cs-text p,
.cs-calltoaction .cs-text span {
  color: #999;
  font-size: 14px;
}

.cs-calltoaction a {
  float: right;
  padding: 0 25px;
  color: #fff !important;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  height: 36px;
  line-height: 36px;
}

/*Call To Action Style End*/

/*Drop Cap Style Start*/

.cs-dropcap {
  margin-bottom: 30px;
}

.cs-dropcap > p {
  font-size: 14px;
  line-height: 22px;
  color: #999;
  font-weight: 400;
}

.cs-dropcap p:first-letter {
  border: 3px solid #ec2c3b;
  color: #ec2c3b;
  float: left;
  font-size: 24px;
  font-weight: 400;
  margin: 9px 15px 0px 0;
  padding: 10px 15px;
}

/*Drop Cap Style End*/

/*Banner News Letter Style Start*/

.banner-news-letter {
  padding: 30px;
  margin-bottom: 30px;
}

.banner-news-letter form .input-holder {
  position: relative;
}

.banner-news-letter form .field-holder {
  position: relative;
  margin-bottom: 15px;
}

.banner-news-letter form .field-holder label {
  width: 100%;
  position: relative;
  margin-bottom: 0px;
}

.banner-news-letter form .field-holder label i {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 14px;
  color: #999;
}

.banner-news-letter form .field-holder .field-input {
  height: 44px;
  line-height: 44px;
  font-size: 14px;
  color: #999;
  padding-left: 35px;
  width: 100%;
  padding-right: 90px;
}

.banner-news-letter form .btn-submit {
  height: 43px;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 0 15px;
  border-radius: 2px;
  border: none;
  position: absolute;
  right: 0px;
  top: 0px;
}

/*Banner News Letter Style End*/

/*Mail-Chimp News Letter Style Start*/

.mailchimp-newsletter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  position: relative;
}

.mailchimp-newsletter .mailchimp_title {
  width: 25%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 25%;
  -webkit-flex: 1 1 25%;
  -moz-box-flex: 1;
  flex: 1 1 25%;
}

.mailchimp-newsletter .mailchimp_title h5 {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  margin-bottom: 0;
  color: #fff !important;
  text-transform: uppercase !important;
}

.mailchimp-newsletter .mailchimp_title span {
  font-size: 13px;
  color: #fff;
  display: block;
}

.mailchimp-newsletter .mailchimp-signup {
  width: 75%;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 75%;
  -webkit-flex: 1 1 75%;
  -moz-box-flex: 1;
  flex: 1 1 75%;
}

.mailchimp-newsletter form .field-holder {
  margin-bottom: 0;
  vertical-align: middle;
  width: 78.888%;
}

.mailchimp-newsletter form .btn-submit {
  border-radius: 0;
  position: initial;
  display: inline-block;
  vertical-align: middle;
  min-width: 150px;
  text-transform: uppercase;
  margin-left: 30px;
  font-weight: 700;
  font-size: 14px;
  padding: 0 20px;
  line-height: 50px;
}

.mailchimp-newsletter form .field-holder .field-input {
  background: none;
  color: #fff;
  height: 50px;
}

.mailchimp-newsletter
  form
  .field-holder
  .field-input::-webkit-input-placeholder {
  color: #fff;
}

.mailchimp-newsletter form .field-holder .field-input:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.mailchimp-newsletter form .field-holder .field-input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.mailchimp-newsletter form .field-holder .field-input:-ms-input-placeholder {
  color: #fff;
}

.mailchimp-newsletter
  form
  .field-holder
  .field-input:focus::-webkit-input-placeholder {
  color: transparent;
}

.mailchimp-newsletter form .field-holder .field-input:focus:-moz-placeholder {
  color: transparent;
}

.mailchimp-newsletter form .field-holder .field-input:focus::-moz-placeholder {
  color: transparent;
}

.mailchimp-newsletter
  form
  .field-holder
  .field-input:focus:-ms-input-placeholder {
  color: transparent;
}

.mailchimp-newsletter form .field-holder label {
  margin-bottom: 0;
  width: 100%;
}

.mailchimp-newsletter form .field-holder label i {
  display: none;
}

.mailchimp-newsletter .icon-spinner8.fa-spin {
  color: #fff;
  top: 50%;
  margin-top: -6px;
  right: 25px;
}

.mailchimp-newsletter .alert-danger {
  background: #e7c3c3;
  display: inline-block;
  min-width: 300px;
  margin: 0;
  padding: 6px 10px;
  position: absolute;
  bottom: -45px;
  border-radius: 0;
}

.mailchimp-newsletter .alert-danger:before {
  position: absolute;
  top: -8px;
  left: 15px;
  width: 0;
  height: 0;
  content: "";
  border-left: 8px solid transparent;
  border-bottom: 8px solid red;
  border-right: 8px solid transparent;
}

.mailchimp-newsletter .alert-danger:after {
  position: absolute;
  top: -7px;
  left: 15px;
  width: 0;
  height: 0;
  content: "";
  border-left: 8px solid transparent;
  border-bottom: 8px solid #e7c3c3;
  border-right: 8px solid transparent;
}

/*Mail-Chimp News Letter Style End*/

/*Food Bakery Editor Style Start*/

.foodbakery_editor {
  margin-bottom: 30px;
}

.foodbakery_editor p span,
.foodbakery_editor span {
  font-size: 14px;
  color: #999;
  font-weight: 400;
  margin-right: 10px;
  display: block;
}

/*Food Bakery Editor Style End*/

/*Main Post Style Start*/

.main-post {
  margin-bottom: 0;
}

.main-post .media-holder.left {
  float: left;
  width: 400px;
}

.main-post .cs-text {
  overflow: hidden;
}

.main-post .media-holder figure img {
  width: 100%;
}

.main-post .cs-text {
  padding: 20px;
  background-color: #fff;
}

.main-post .cs-text h4 {
  margin-bottom: 5px;
}

.main-post .img-frame figure img {
  width: auto;
}

.main-post.promo-box {
  margin-bottom: 0px;
}

/*Main Post Style End*/

/*Contact Info Style Start*/

.contact-info ul li:last-child .text-holder {
  margin-right: 0px;
}

.contact-info {
  padding: 0px 0px 20px 0;
  margin: 0px;
}

.contact-info li {
  list-style: none;
  padding: 0px;
  margin: 0px 0 5px 0;
  vertical-align: middle;
  font-size: 14px;
  color: #999;
  font-weight: 400;
}

.contact-info li i {
  display: inline-block;
  margin-right: 5px;
  font-size: 16px;
  color: #666;
  vertical-align: middle;
}

.contact-info li .address-text {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  color: #999;
  font-weight: 400;
}

/*Contact Info Style End*/

/*Site Maps Links Style Start*/

.site-maps-links {
  margin-bottom: 25px;
}

.site-maps-links ul {
  padding: 0px;
  margin: 0px;
}

.site-maps-links ul li {
  list-style: none;
  padding: 0px;
  margin: 0px 0 5px 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  line-height: 22px;
}

.site-maps-links ul li a {
  display: block;
  font-size: 14px;
  color: #999;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: none;
  outline: none;
  font-weight: 400;
}

/*Site Maps Links Style End*/

.cs-map-section,
.image-frame.fancy {
  margin-bottom: 25px;
}

.button_style {
  margin-bottom: 20px;
}

/*Table Style Start*/

.cs-pricing-table .table {
  margin-bottom: 30px;
}

.cs-pricing-table .table thead tr th,
.cs-pricing-table .table tbody tr td {
  text-align: left;
}

.cs-pricing-table .table thead tr th {
  font-size: 14px;
  color: #000;
  font-weight: 700;
  background-color: #fff;
  padding: 12px 15px;
}

.cs-pricing-table .table tbody tr td {
  font-size: 14px;
  color: #999;
  font-weight: 400;
  background-color: #fff;
  padding: 10px 12px;
}

/*Table Style End*/

.testimonial {
  margin-bottom: 30px;
}

/*Team Grid Style Start*/

.team.team-grid {
  margin-bottom: 30px;
}

.team.team-grid .img-holder figure {
  position: relative;
}

.team.team-grid:hover .img-holder {
  overflow: hidden;
}

.team.team-grid:hover .img-holder figcaption {
  opacity: 1;
  visibility: visible;
}

.team.team-grid .img-holder figcaption {
  position: absolute;
  left: 0px;
  top: 50%;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.team.team-grid .img-holder figcaption a {
  position: absolute;
  top: 50%;
  font-size: 16px;
  color: #fff;
}

.team.team-grid .img-holder figure img {
  width: 100%;
}

.team.team-grid .team-info {
  padding: 20px 15px 10px 15px;
  background-color: #fff;
}

.team.team-grid .team-info .position {
  display: block;
  font-size: 14px;
  line-height: 14px;
  margin: 0 0 7px;
  text-transform: uppercase;
}

.team.team-grid .team-info h2 {
  margin-bottom: 5px;
}

.team.team-grid .team-info p {
  font-size: 14px;
  color: #999;
  font-weight: 400;
}

/*Team Grid Style End*/

/*Cs Tweets Ticker Style Start*/

.cs-tweets-ticker {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
}

.cs-tweets-ticker .cs-testimonial-slider {
  padding: 0px;
  margin: 0px;
}

.cs-tweets-ticker .cs-testimonial-slider li {
  list-style: none;
  display: block;
  padding: 0px;
  margin: 0px;
  color: #999;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

/*Cs Tweets Ticker Style End*/

/*Authore Style Start*/

.author-list {
  padding: 0px;
  margin: 0px 0 20px 0 !important;
}

.author-list li {
  list-style: none;
  padding: 0px;
  margin: 0px 0 15px 0;
}

.author-list .img-holder {
  float: left;
  margin-right: 15px;
  width: 70px;
}

.author-list .img-holder figure img {
  width: 100%;
}

.author-list .text-holder {
  overflow: hidden;
}

.author-list .text-holder h5 {
  margin-bottom: 0px;
}

.author-list .text-holder .btn-view-post {
  font-size: 14px;
  color: #999;
  font-weight: 400;
}

.author-list .text-holder .btn-view-post i {
  display: inline-block;
  margin-left: 2px;
  color: #999;
  font-size: 16px;
  vertical-align: middle;
}

/*Authore Post Style Start*/

.author-post-list .img-holder {
  width: 100%;
}

.author-post-list .img-holder figure {
  position: relative;
}

.author-post-list .img-holder figure a {
  width: 70px;
  float: left;
}

.author-post-list .img-holder figure a img {
  width: 100%;
}

.author-post-list .img-holder figure figcaption {
  overflow: hidden;
  padding-left: 15px;
}

.author-post-list .img-holder figure figcaption span {
  font-size: 14px;
  color: #999;
  font-weight: 400;
  display: block;
  margin-bottom: 10px;
}

/*Authore Style End*/

.counter-sec.counter-shortcode {
  width: 100%;
  text-align: left;
}

.counter-sec.counter-shortcode.classic {
  text-align: center;
  margin: 0 auto;
  width: 620px;
}

.counter-sec.counter-shortcode.classic strong {
  position: relative;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 17px;
  background: #39b44d;
  border-radius: 50%;
  display: inline-block;
  margin: 0 0 15px 0;
}

.counter-sec.counter-shortcode.classic span {
  display: block;
}

.counter-sec.counter-shortcode.classic strong:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: 1;
}

.counter-sec.counter-shortcode.classic strong:before {
  -webkit-box-shadow: 0 0 0 6px rgba(244, 244, 244, 0.6);
  box-shadow: 0 0 0 6px rgba(244, 244, 244, 0.6);
}

.counter-sec.counter-shortcode.classic strong:before {
  -webkit-animation: 2.5s anim-effect-front 0s linear infinite;
  -moz-animation: 2.5s anim-effect-front 0s linear infinite;
  animation: 2.5s anim-effect-front 0s linear infinite;
}

.counter-sec.counter-shortcode.classic strong:after {
  position: absolute;
  content: "";
  width: 60px;
  height: 60px;
  top: 0;
  left: 0;
  border-radius: 50%;
  z-index: 2;
}

.counter-sec.counter-shortcode.classic strong:after {
  -webkit-box-shadow: 0 0 0 3px rgba(244, 244, 244, 0.3);
  box-shadow: 0 0 0 3px rgba(244, 244, 244, 0.3);
}

.counter-sec.counter-shortcode.classic strong:after {
  -webkit-animation: 2.5s anim-effect-back 0s linear infinite;
  -moz-animation: 2.5s anim-effect-back 0s linear infinite;
  animation: 2.5s anim-effect-back 0s linear infinite;
}

@-webkit-keyframes anim-effect-back {
  0% {
    -webkit-box-shadow: 0 0 0 3px rgba(244, 244, 244, 0.1);
    box-shadow: 0 0 0 3px rgba(244, 244, 244, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1.5);
    transform: scale3d(1, 1, 1.5);
  }
  50% {
    -webkit-box-shadow: 0 0 0 4px rgba(244, 244, 244, 0.6);
    box-shadow: 0 0 0 4px rgba(244, 244, 244, 0.6);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1.5);
    transform: scale3d(1, 1, 1.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0 5px rgba(244, 244, 244, 0);
    box-shadow: 0 0 0 5px rgba(244, 244, 244, 0);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1.5);
    transform: scale3d(1, 1, 1.5);
  }
}

@-moz-keyframes anim-effect-back {
  0% {
    box-shadow: 0 0 0 2px rgba(244, 244, 244, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1.5);
    -moz-transform: scale3d(1, 1, 1.5);
    transform: scale3d(1, 1, 1.5);
  }
  50% {
    box-shadow: 0 0 0 3px rgba(244, 244, 244, 0.6);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1.5);
    -moz-transform: scale3d(1, 1, 1.5);
    transform: scale3d(1, 1, 1.5);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(244, 244, 244, 0);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1.5);
    -moz-transform: scale3d(1, 1, 1.5);
    transform: scale3d(1, 1, 1.5);
  }
}

@keyframes anim-effect-back {
  0% {
    -webkit-box-shadow: 0 0 0 2px rgba(244, 244, 244, 0.1);
    box-shadow: 0 0 0 2px rgba(244, 244, 244, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1.5);
    -moz-transform: scale3d(1, 1, 1.5);
    transform: scale3d(1, 1, 1.5);
  }
  50% {
    -webkit-box-shadow: 0 0 0 3px rgba(244, 244, 244, 0.6);
    box-shadow: 0 0 0 3px rgba(244, 244, 244, 0.6);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1.5);
    -moz-transform: scale3d(1, 1, 1.5);
    transform: scale3d(1, 1, 1.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0 5px rgba(244, 244, 244, 0);
    box-shadow: 0 0 0 5px rgba(244, 244, 244, 0);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 1.5);
    -moz-transform: scale3d(1, 1, 1.5);
    transform: scale3d(1, 1, 1.5);
  }
}

@-webkit-keyframes anim-effect-front {
  0% {
    -webkit-box-shadow: 0 0 0 2px rgba(232, 232, 232, 0.1);
    box-shadow: 0 0 0 2px rgba(232, 232, 232, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  50% {
    -webkit-box-shadow: 0 0 0 4px rgba(232, 232, 232, 0.6);
    box-shadow: 0 0 0 4px rgba(232, 232, 232, 0.6);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0 5px rgba(232, 232, 232, 0);
    box-shadow: 0 0 0 5px rgba(232, 232, 232, 0);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
}

@-moz-keyframes anim-effect-front {
  0% {
    box-shadow: 0 0 0 2px rgba(232, 232, 232, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    -moz-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  50% {
    box-shadow: 0 0 0 4px rgba(232, 232, 232, 0.6);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    -moz-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(232, 232, 232, 0);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 0.5);
    -moz-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
}

@keyframes anim-effect-front {
  0% {
    -webkit-box-shadow: 0 0 0 2px rgba(232, 232, 232, 0.1);
    box-shadow: 0 0 0 2px rgba(232, 232, 232, 0.1);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    -moz-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  50% {
    -webkit-box-shadow: 0 0 0 4px rgba(232, 232, 232, 0.6);
    box-shadow: 0 0 0 4px rgba(232, 232, 232, 0.6);
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 0.5);
    -moz-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
  100% {
    -webkit-box-shadow: 0 0 0 5px rgba(232, 232, 232, 0);
    box-shadow: 0 0 0 5px rgba(232, 232, 232, 0);
    opacity: 0;
    -webkit-transform: scale3d(1, 1, 0.5);
    -moz-transform: scale3d(1, 1, 0.5);
    transform: scale3d(1, 1, 0.5);
  }
}

/*Progress Info Style Start*/

.progress-info {
  margin-bottom: 15px;
  position: relative;
}

.progress-info span {
  font-size: 16px;
  color: #2f313a;
  font-weight: 700;
  display: block;
  line-height: 20px;
  margin-bottom: 10px;
}

.progress-info .progress {
  margin: 0px;
  position: relative;
  background-color: #fff;
  background-image: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0px;
}

.progress-info small {
  position: absolute;
  right: 10px;
  z-index: 1;
  font-size: 14px;
  color: #999;
  line-height: 21px;
}

.progress-info .progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}

/*Progress Info Style End*/

/*Price Table Style Start*/

.pricetable-holder.center {
  text-align: center;
}

.pricetable-holder {
  font-family: "Montserrat", sans-serif;
  margin-bottom: 30px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 3px rgba(160, 160, 160, 0.62);
  box-shadow: 0 0 3px rgba(160, 160, 160, 0.62);
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;
}

.pricetable-holder.active {
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  margin: 7px auto 0 auto;
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  width: -webkit-calc(100% - 10px);
  width: -moz-calc(100% - 10px);
  width: calc(100% - 10px);
}

.pricetable-holder .cs-price {
  border: 0;
  position: relative;
  min-width: 75%;
  display: inline-block;
  margin: auto;
  background-color: #333333;
  border-radius: 0 0 40% 40%;
  margin-bottom: 40px;
}

.pricetable-holder.active .cs-price {
  background-color: #39b44d;
}

.pricetable-holder .cs-price h2 {
  font: 700 Normal 21px/22px "Roboto", sans-serif !important;
  padding: 35px 15px 20px 15px;
  position: relative;
  z-index: 1;
  color: #fff !important;
  margin-bottom: 0;
}

.pricetable-holder .cs-price > span {
  font-size: 50px;
  line-height: 35px;
  color: #fff;
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 0;
}

.pricetable-holder .cs-price > span small {
  font-size: 20px;
  font-weight: 400;
  vertical-align: sub;
  line-height: 18px;
  top: -13px;
  position: relative;
  display: inline-block;
}

.pricetable-holder .cs-price > span em {
  font-size: 14px;
  font-weight: 400;
  margin-left: -10px;
  padding-left: 0;
  text-transform: capitalize;
  font-style: normal;
}

.pricetable-holder p {
  font-size: 16px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 20px;
}

.pricetable-holder ul {
  margin-bottom: 34px;
}

.pricetable-holder ul li {
  list-style: none;
  padding: 10px;
}

.pricetable-holder ul li,
.pricetable-holder ul li strong {
  color: #9698a0;
  font-weight: 300;
  font-size: 14px;
}

.pricetable-holder ul li:nth-child(even) {
  background: #fbfbfb;
}

.pricetable-holder .cs-price:before {
  content: "";
  border-radius: 0 0 50% 50%;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  margin: auto;
  background-color: inherit;
  z-index: 0;
}

.pricetable-holder .foodbakery-subscribe-pkg-btn,
.pricetable-holder .foodbakery-subscribe-pkg {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  display: inline-block;
  background: #39b44d;
  font-size: 14px;
  text-transform: uppercase;
  padding: 9px 36px;
  color: #fff;
  border: 0;
  border-radius: 30px;
  line-height: 18px;
}

.pricetable-holder .foodbakery-subscribe-pkg-btn i,
.pricetable-holder .foodbakery-subscribe-pkg i {
  display: inline-block;
}

.pricetable-holder .foodbakery-subscribe-pkg-btn input[type="submit"],
.pricetable-holder .foodbakery-subscribe-pkg input[type="submit"] {
  border: 0;
  background: transparent;
}

.pricetable-holder
  .foodbakery-subscribe-pkg-btn
  .pricetable-holder
  .foodbakery-subscribe-pkg
  i {
  vertical-align: middle;
}

.pricetable-holder .category {
  display: inline-block;
  height: 16px;
  left: -24px;
  line-height: 12px;
  position: absolute;
  top: 22px;
  -webkit-transform: rotate(-50deg);
  -moz-transform: rotate(-50deg);
  -ms-transform: rotate(-50deg);
  transform: rotate(-50deg);
  width: 100px;
  background-color: #000;
}

.pricetable-holder .category em {
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 14px;
  text-transform: uppercase;
}

.pricetable-holder p,
.pricetable-holder span {
  font-size: 14px;
  color: #999;
  padding: 0 30px;
  margin-bottom: 15px;
  display: block;
}

.pricetable-holder .button-holder {
  display: block;
}

.pricetable-holder .button-holder a {
  display: inline-block;
  text-align: center;
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 400;
  outline: none;
  text-decoration: none;
}

.pricetable-holder .button-holder a i {
  display: inline-block;
  margin: -1px 0 0 0;
  vertical-align: middle;
}

.advance .pricetable-holder {
  padding-bottom: 38px;
}

.advance .pricetable-holder.active {
  padding-bottom: 30px;
}

.advance .pricetable-holder .cs-price {
  background-color: transparent !important;
  width: 100%;
  margin-bottom: 20px;
}

.advance .pricetable-holder .cs-price > span {
  font-size: 48px;
  color: #333333;
  background: none !important;
  border-bottom: 1px solid #dfe1e7;
  font-weight: 700;
  position: relative;
}

.advance .pricetable-holder .cs-price > span strong {
  font-size: 16px;
  display: block;
  text-transform: uppercase;
}

.advance .pricetable-holder .cs-price > span small {
  font-weight: 300;
  margin-right: 5px;
}

.advance .pricetable-holder .cs-price > span em {
  font-weight: 300;
}

.advance .pricetable-holder .cs-price > span:after {
  content: "";
  display: block;
  height: 1px;
  background: #dfe1e7;
  position: absolute;
  bottom: 0;
}

.advance .pricetable-holder.active {
  -webkit-box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.29);
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.29);
  border: 4px solid #39b44d;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  margin-top: 0;
}

.advance .pricetable-holder .cs-price h2 {
  color: #333333 !important;
  padding: 28px 15px 13px 15px;
  text-transform: uppercase !important;
  letter-spacing: 0 !important;
}

.advance .pricetable-holder ul li:nth-child(even) {
  background: transparent;
}

.advance .pricetable-holder ul li {
  color: #666666;
}

.advance .pricetable-holder ul li {
  padding: 5px;
}

.advance .foodbakery-subscribe-pkg {
  background: transparent;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 18px;
  border: 1px solid #e1e1e1;
  color: #666;
  border-radius: 2px;
  padding: 11px 46px;
}

.advance .pricetable-holder.active .foodbakery-subscribe-pkg {
  background: #39b44d;
  color: #fff;
}

/*Price Table Style End*/

/*Cs Icon List Style Start*/

.cs-icon-list {
  padding: 0px;
  margin: 0px 0 30px 0;
}

.cs-icon-list li {
  list-style: none;
  padding: 0px;
  margin: 0px 0 10px;
  border: none;
  font-size: 14px;
  color: #999;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 15px;
}

.cs-icon-list li i {
  display: inline-block;
  margin-right: 8px;
  font-size: 16px;
  color: #999;
  vertical-align: middle;
}

.cs-icon-list li:last-child {
  margin-bottom: 0px;
}

/*Cs Icon List Style End*/

/*Food Bakery Tabs Style Start*/

.foodbakery-nav .nav-list {
  padding: 0px;
  margin: 0px;
}

.foodbakery-nav .nav-list li {
  list-style: none;
  display: inline-block;
  margin-right: -4px;
  padding: 0px;
  border: none;
}

.foodbakery-nav .nav-list li a {
  display: block;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  color: #999;
}

.foodbakery-nav .nav-list li a span {
  display: inline-block;
  font-size: 14px;
  color: #999;
  margin-right: 5px;
}

.foodbakery-nav .nav-list li a strong {
  display: inline-block;
  font-size: 14px;
  color: #666;
}

/*Food Bakery Tabs Style End*/

/*Horizontal Tabs Style Start*/

.cs-tabs.horizontal-tabs {
  margin-bottom: 30px;
}

.cs-tabs.horizontal-tabs .tab-content {
  padding: 20px;
  background-color: #fff;
}

.cs-tabs.horizontal-tabs .tab-content .tab-pane span,
.cs-tabs.horizontal-tabs .tab-content .tab-pane p {
  font-size: 14px;
  color: #999 !important;
  font-weight: 400 !important;
}

.cs-tabs.horizontal-tabs .nav-tabs {
  padding: 0px;
  margin: 0px;
}

.cs-tabs.horizontal-tabs .nav-tabs li {
  list-style: none;
  float: left;
  margin-bottom: -1px;
  position: relative;
}

.cs-tabs.horizontal-tabs .nav-tabs li a {
  border: medium none;
  border-radius: 0;
  color: #454751;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  padding: 10px 20px;
  position: relative;
}

.cs-tabs.horizontal-tabs .nav-tabs li a i {
  display: inline-block;
  font-size: 14px;
  color: #999;
  margin-right: 5px;
  vertical-align: middle;
}

.cs-tabs.horizontal-tabs .nav-tabs li:after,
.cs-tabs.horizontal-tabs .nav-tabs li.active:after {
  background-color: #d2d4db;
  content: "";
  height: 10px;
  position: absolute;
  right: 0;
  top: 17px;
  width: 1px;
}

.cs-tabs.horizontal-tabs .nav-tabs li.active::after,
.cs-tabs.horizontal-tabs .nav-tabs li:hover::after {
  display: none;
}

.cs-tabs.horizontal-tabs .nav-tabs li.active a,
.cs-tabs.horizontal-tabs .nav-tabs li.active a:hover,
.cs-tabs.horizontal-tabs .nav-tabs li.active a:focus,
.cs-tabs.horizontal-tabs .nav-tabs > li > a:hover {
  background-color: #fff;
  border: medium none;
  -webkit-box-shadow: 0 -5px 4px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -5px 4px -2px rgba(0, 0, 0, 0.1);
}

/*Horizontal Tabs Style End*/

/*Vertical Tabs Style Start*/

.cs-faq-tabs.vertical-tabs {
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
}

.cs-faq-tabs.vertical-tabs .nav-tabs {
  padding: 0px;
  margin: 0px;
  width: 25%;
  float: left;
}

.cs-faq-tabs.vertical-tabs .nav-tabs li {
  width: 100%;
  list-style: none;
}

.cs-faq-tabs.vertical-tabs .tab-content {
  width: 75%;
  float: left;
  padding: 15px 30px 20px 30px;
  background-color: #fff;
  min-height: 150px;
  border: 1px solid #eeeeee;
}

.cs-faq-tabs.vertical-tabs .nav-tabs li a i {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
}

.cs-faq-tabs.vertical-tabs .nav-tabs li a {
  display: block;
  font-size: 14px;
  background-color: #f7f7f7;
  color: #000;
  margin-right: -1px;
  font-weight: 400;
  border-radius: 0px;
  border: 1px solid #eeeeee;
  padding: 12px 15px;
}

.cs-faq-tabs.vertical-tabs .nav-tabs li.active a,
.cs-faq-tabs.vertical-tabs li.active a:hover,
.cs-faq-tabs.vertical-tabs li.active a:focus {
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #eeeeee;
  border-radius: 0px;
  border-right: transparent;
}

.cs-faq-tabs.vertical-tabs .nav-tabs li.active:first-child a,
.cs-faq-tabs.vertical-tabs li.active:first-child a:hover,
.cs-faq-tabs.vertical-tabs li.active:first-child a:focus {
  border-radius: 0;
  border-right: transparent;
}

/*Vertical Tabs Style End*/

/*Calltoaction Style Start*/

.cs-calltoaction.simple {
  padding: 0;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.cs-calltoaction.simple a {
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cs-calltoaction.simple .cs-text strong {
  font-size: 30px;
  text-transform: uppercase;
  display: block;
  line-height: 40px;
  letter-spacing: 1px;
}

.cs-calltoaction.simple .cs-text {
  font-size: 16px;
  color: #fff;
}

.cs-calltoaction.fancy {
  display: inline-block;
  padding: 0;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.cs-calltoaction.fancy .img-holder {
  margin: 0 0 40px;
}

.cs-calltoaction.fancy .img-holder i {
  color: #fff;
  font-size: 60px;
}

.cs-calltoaction.fancy .cs-text {
  margin: 0 0 25px;
  font-size: 16px;
  color: #fff;
  width: 100%;
}

.cs-calltoaction.fancy .cs-text strong {
  font-size: 40px;
  margin: 0 0 25px;
  display: block;
  text-transform: uppercase;
  line-height: 46px;
}

.cs-calltoaction.fancy a {
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  float: none;
  display: inline-block;
  height: 44px;
  line-height: 44px;
}

/*Calltoaction Style End*/

/*Short Code End*/

span.list-feature-item {
  background: #00a79d;
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  line-height: normal;
  padding: 3px 6px;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
}

.no-restaurant-match-error h6 i,
.no-restaurant-match-error h6 strong {
  display: inline-block;
  vertical-align: middle;
}

.btn-top {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.back-to-top {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  outline: none;
  position: relative;
}

.back-to-top i {
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-right: 8px;
  height: 15px;
  width: 15px;
}

.back-to-top:hover,
.back-to-top:focus {
  color: #fff;
}

/* cetagories fancy style start */

.categories-holder.fancy {
  background: none;
  border: none;
}

.categories-holder.fancy .categories-list {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
  padding: 10px;
  position: relative;
}

.categories-holder.fancy .categories-list:after {
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 0;
  position: absolute;
  top: 22px;
  left: -13px;
  width: 1px;
  height: 63px;
  content: "";
}

.categories-holder.fancy .categories-list a {
  display: block;
}

.categories-holder.fancy .categories-list a:hover {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px 0px 10px 0px;
}

.categories-holder.fancy .categories-list a:focus {
  background: #39b44d;
  border-radius: 10px 0px 10px 0px;
}

.categories-holder.fancy .categories-list h6,
.categories-holder.fancy .categories-list h6 a {
  margin-bottom: 0;
}

.categories-holder.fancy [class*="col-"]:first-child .categories-list:after {
  background: none;
}

/* cetagories fancy style start */

/* location fancy style start */

.location-holder.fancy {
  border: none;
  background: none;
}

.location-holder.fancy ul.location-list {
  border-left: 1px solid #e7e7e7;
  margin: 0 -20px 24px;
  padding: 15px 0 15px 40px;
}

.location-holder.fancy ul.location-list li span {
  color: #e51a1b;
}

.location-holder.fancy .more-btn-holder {
  padding-top: 30px;
}

.location-holder.fancy [class*="col-"]:first-child .location-list {
  border-left: 0;
}

.location-holder.fancy [class*="col-"]:nth-child(odd) .location-list {
  background: #fff;
}

.location-holder.fancy [class*="col-"]:nth-child(even) .location-list {
  background: #f7f7f7;
}

/* location fancy style end */

/* icon-box classic style start */

.icon-boxes.classic {
  background: #fff;
  padding: 20px 0px 75px;
  border-radius: 20px;
  position: relative;
  margin: 4px 40px 30px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.icon-boxes.classic:before {
  background-color: #e7ecee;
  border-radius: 100%;
  font-size: 16px;
  color: #aaa;
  content: counter(mega-step);
  position: absolute;
  bottom: 20px;
  left: 50%;
  height: 35px;
  line-height: 35px;
  width: 35px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
}

.icon-boxes.classic:after {
  font-size: 36px;
  color: #ccc;
  position: absolute;
  right: -70px;
  top: 50%;
  content: "\e5c8";
  font-family: "icomoon";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cs-icon-boxes-list [class*="col-"]:last-child .icon-boxes.classic:after {
  display: none;
}

.icon-boxes.classic .img-holder {
  float: none;
}

.icon-boxes.classic .text-holder {
  border-bottom: 1px dashed #cfcfcf;
  overflow: visible;
  padding: 0 15px 8px;
  position: relative;
}

.icon-boxes.classic .text-holder:after,
.icon-boxes.classic .text-holder:before {
  background: #f2f6fb;
  content: "";
  position: absolute;
  left: -12px;
  bottom: -12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  -webkit-box-shadow: -2px 0 0 rgba(0, 0, 0, 0.1) inset;
  box-shadow: -2px 0 0 rgba(0, 0, 0, 0.1) inset;
}

.icon-boxes.classic .text-holder:after {
  left: auto;
  right: -12px;
  -webkit-box-shadow: 2px 0 0 rgba(0, 0, 0, 0.1) inset;
  box-shadow: 2px 0 0 rgba(0, 0, 0, 0.1) inset;
}

.icon-boxes.classic .text-holder h3 {
  /*margin: 0 -50px 35px;*/
  margin-bottom: 10px;
}

.icon-boxes.classic .text-holder h3 a {
  display: block;
}

/* icon-box classic style start */

/* Categories modern style start */

.categories-holder.modern {
  border: none;
  padding: 0;
  background: none;
  width: 800px;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

.categories-holder.modern .categories-list h6 {
  font-size: 14px !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}

.categories-holder.modern .categories-list:hover h6 {
  color: #fff !important;
}

.categories-holder.modern .categories-list {
  background: #fff;
  margin-bottom: 0;
  padding: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  min-height: 125px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.categories-holder.modern .categories-list:hover {
  background: red;
}

.categories-holder.modern .categories-list a {
  display: inline-block;
}

.categories-holder.modern .categories-list:after {
  background: #e1e1e1;
  margin-bottom: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1px;
  height: 100%;
  content: "";
}

.categories-holder.modern [class*="col-"]:first-child .categories-list:after {
  background: none;
}

.categories-holder.modern [class*="col-"] {
  padding: 0;
}

.categories-holder.modern .categories-list i {
  color: #505050 !important;
}

.categories-holder.modern .categories-list:hover a i,
.categories-holder.modern .categories-list:hover h6 span {
  color: #fff !important;
}

/* Categories modern style start */

/* testimonial classic slider style start */

.testimonial.classic {
  text-align: center;
}

.testimonial.classic .testimonial-holder .img-holder {
  position: relative;
  margin-bottom: 25px;
  margin-right: 0;
  width: 114px;
  display: inline-block;
  float: none;
}

.testimonial.classic .testimonial-holder .img-holder:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "\ea34";
  font-family: "icomoon";
}

.testimonial.classic .testimonial-holder .text-holder {
  margin-top: 20px;
}

.testimonial.classic .testimonial-holder .text-holder p {
  margin-bottom: 30px;
  font-size: 15px;
  color: #767676 !important;
  line-height: 30px;
  font-weight: 600;
}

.testimonial.classic .author-detail .auther-name {
  margin-bottom: 30px;
}

.testimonial.classic .author-detail .auther-name span {
  font-size: 20px;
  color: #d91d1d;
  font-weight: 600;
}

.testimonial.classic .testimonial-holder .img-holder:after {
  top: -12px;
  left: -52px;
  z-index: -1;
  font-size: 205px;
  color: #ededed;
}

.testimonial.classic .swiper-pagination {
  position: inherit;
}

.testimonial.classic .swiper-pagination-bullet {
  background: #d91d1d;
  margin-left: 10px;
  width: 10px;
  height: 10px;
}

.section-title {
  margin-bottom: 0;
}

/* testimonial classic slider style start */

/* testimonial fancy slider style start */

.testimonial.fancy {
  width: 726px;
  margin: 0 auto;
}

.testimonial.fancy .testimonial-holder {
  text-align: center;
}

.testimonial.fancy .testimonial-holder .img-holder {
  position: relative;
  float: none;
  display: inline-block;
  width: 64px;
}

.testimonial.fancy .testimonial-holder .img-holder:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "\f10e";
  font-family: "icomoon";
  color: #e51a1b;
}

.testimonial.fancy .swiper-pagination-bullet-active {
  background: #e51a1b !important;
  border: 0 !important;
}

.testimonial.fancy .swiper-pagination {
  position: inherit;
  text-align: center;
}

.testimonial.fancy .swiper-pagination-clickable .swiper-pagination-bullet {
  margin-top: 30px;
  margin-right: 10px;
  width: 10px;
  height: 10px;
}

.testimonial.fancy .swiper-pagination-bullet {
  background: none;
  border: 1px solid;
}

/* testimonial fancy slider style End */

/* testimonial simple slider style start */

.testimonial.simple {
  text-align: center;
  padding: 0 220px;
}

.testimonial.simple .img-holder {
  float: none;
  display: inline-block;
  width: 115px;
  margin-right: 0;
  margin-bottom: 25px;
  position: relative;
  border: 3px solid #fff;
  border-radius: 100%;
}

.testimonial.simple .text-holder p {
  font-size: 15px;
  color: #767676;
  margin-bottom: 35px;
}

.testimonial.simple .author-detail .auther-name span {
  font-size: 20px;
  font-weight: 700;
  color: #d91d1d;
}

.testimonial.simple .testimonial-holder .img-holder:after {
  position: absolute;
  top: -30px;
  left: 50%;
  content: "\eb2b";
  font-family: "icomoon";
  color: #ededed;
  font-size: 150px;
  z-index: -1;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.testimonial.simple .swiper-pagination {
  position: inherit;
}

.testimonial.simple .swiper-pagination-clickable .swiper-pagination-bullet {
  margin-top: 30px;
  margin-right: 4px;
  width: 10px;
  height: 10px;
}

.testimonial.simple .swiper-pagination-bullet {
  background: none;
  border: 2px solid #d91d1d;
  opacity: 1;
}

.testimonial.simple .swiper-pagination-bullet-active {
  background: #e51a1b;
}

/* testimonial simple slider style End */

/* Footer Style 3 Star */

#footer.footer-style-3 .widget,
#footer.footer-style-3 .widget p,
#footer.footer-style-3 .widget a {
  font-weight: 600 !important;
}

#footer.footer-style-3 .widget.widget-categories li a {
  padding-left: 0;
  font-weight: 600;
}

#footer.footer-style-3 .widget.widget-categories li a:hover {
  margin-left: 10px;
}

#footer.footer-style-3 .widget-categories ul li a:before {
  display: none;
}

#footer.footer-style-3 .widget-connect ul li {
  padding-left: 55px;
}

#footer.footer-style-3 .widget-connect ul li span {
  height: 40px;
  border-radius: 100%;
  width: 40px;
}

#footer.footer-style-3 .widget-connect ul li span i {
  color: #fff !important;
}

#footer.footer-style-3 .footer-widget {
  padding-bottom: 0;
}

.footer-style-3 .footer-logo {
  margin-right: 18px;
}

.footer-style-3 .copy-right {
  display: inline-block;
  padding: 0;
  margin-bottom: 0;
}

.footer-style-3 .copy-right p {
  margin-bottom: 0;
  line-height: normal;
}

.footer-style-3 .social-media {
  margin-bottom: 0;
  margin-left: auto;
  line-height: normal;
}

.footer-style-3 .social-media li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-left: 15px;
}

.footer-style-3 .copyright-inner {
  border-top: 1px solid #e6e6e6;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

.footer-style-3 .social-media li a {
  background: none;
  font-size: 20px;
  color: #b8b8b8;
}

.footer-style-3 .copy-right p {
  text-transform: capitalize;
  font-size: 13px;
}

.footer-style-3 .copy-right p a {
  text-transform: capitalize;
  font-weight: 600;
  font-size: 13px;
}

#footer.footer-style-3 .textwidget {
  font-weight: 600;
}

/* Footer Style 3 End */

/* Element Title with Icon */

.element-title.has-icon .title-content {
  position: relative;
  display: inline-block;
}

.element-title.has-icon .title-content .heading-holder {
  display: inline-block;
  vertical-align: middle;
}

.element-title.has-icon .title-content i {
  font-size: 30px;
  display: inline-block;
  vertical-align: middle;
}

.element-title.has-icon.left .heading-holder ~ i {
  padding-left: 20px;
}

.element-title.right.has-icon {
  text-align: right;
}

.element-title.top-center.has-icon {
  text-align: center;
}

.element-title.top-center.has-icon i {
  margin-bottom: 15px;
  display: block;
  text-align: center;
}

/* Fix issues with jquery overlay blocking checkout button */

.woocommerce .blockUI.blockOverlay {
  position: relative !important;
  display: none !important;
}

/* Overlay For Page Section Style Start */

.bg_overlay {
  position: relative;
}

.bg_overlay:before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: #231f48;
  background: -webkit-linear-gradient(
    top,
    rgba(35, 31, 72, 0.77) 0%,
    rgba(25, 58, 0, 0.57) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(35, 31, 72, 0.77)),
    to(rgba(25, 58, 0, 0.57))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(35, 31, 72, 0.77) 0%,
    rgba(25, 58, 0, 0.57) 100%
  );
  background: -moz-
    oldlinear-gradient(
      left,
      rgba(35, 31, 72, 0.77) 0%,
      rgba(25, 58, 0, 0.57) 100%
    );
  background: linear-gradient(
    to right,
    rgba(35, 31, 72, 0.77) 0%,
    rgba(25, 58, 0, 0.57) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#231f48', endColorstr='#193a00', GradientType=0);
}

/* Overlay For Page Section Style End */

/*Footer Style-4 Start*/

#footer.footer-style-4 .footer-widget {
  padding: 60px 0 60px;
}

#footer.footer-style-4 .widget-title {
  margin-bottom: 40px;
}

.footer-style-4 .widget-title:before {
  background-color: #d91d1d;
  position: absolute;
  bottom: -20px;
  left: 0;
  content: "";
  height: 1px;
  width: 30px;
}

.footer-style-4 .textwidget {
  margin-bottom: 30px;
}

.footer-style-4 .social-media {
  margin: 0;
  padding: 0;
}

.footer-style-4 .social-media li {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
}

.footer-style-4 .social-media > li > a {
  background: none;
  border: 1px solid #dbdbdb;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  display: block;
  text-align: center;
  margin-right: 10px;
  width: 40px;
}

#footer.footer-style-4 .social-media > li > a {
  color: #818181 !important;
}

#footer.footer-style-4 .social-media > li > a:hover i {
  color: #fff !important;
}

.footer-style-4 .widget.widget_text .textwidget img,
.footer-style-4 .footer-logo {
  display: none;
}

.footer-style-4 .copy-right {
  margin-bottom: 0;
  padding-top: 20px;
}

.footer-style-4 .widget-connect ul li {
  padding-left: 40px;
  margin-bottom: 25px;
}

.footer-style-4 .widget-connect ul li span {
  background: none !important;
}

.footer-style-4 .widget-connect ul li span i {
  font-size: 16px;
  position: initial;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  transform: none;
  display: inline-block;
}

.footer-style-4 .widget-connect p {
  font-size: 13px;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
}

#footer.footer-style-4 .widget-connect p,
#footer.footer-style-4 .widget-connect ul li p a {
  color: #767676 !important;
}

.footer-style-4 .copy-right p {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.footer-style-4 .copy-right p a {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
}

/*Footer Style-4 End*/

/*Court Header Style Start*/

#header.court .main-header {
  padding: 18px 50px 18px 0;
}

#header.court.pinned .main-header {
  padding: 10px 25px;
}

#header.court .main-header .wide > .row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#header.court.transparent-header .main-header .main-nav {
  float: none;
  padding: 0 25px 0 0;
}

#header.court .slicknav_menu {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

#header.court .main-navigation {
  display: none;
}

#header.court .slicknav_btn {
  border: 1px solid transparent;
  border-radius: 0;
  width: auto;
  min-width: 125px;
  height: 73px;
}

#header.court.pinned .slicknav_btn {
  border-color: #fff;
  border-radius: 3px;
  height: 55px;
}

#header.court .slicknav_btn .slicknav_icon {
  height: 3px;
  width: 38px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

#header.court .slicknav_btn .slicknav_icon:before {
  top: -9px;
}

#header.court .slicknav_menu .slicknav_icon:after {
  bottom: -9px;
}

#header.court .slicknav_btn.slicknav_open .slicknav_icon:before {
  top: 0;
}

#header.court .slicknav_btn.slicknav_open .slicknav_icon:after {
  bottom: 0;
}

#header.court .slicknav_menu .slicknav_icon:before,
#header.court .slicknav_menu .slicknav_icon:after {
  height: 3px;
}

#header.court.pinned .main-nav .slicknav_nav {
  margin-top: 25px;
}

#header.court .main-header .login-option a.login-popup {
  font-size: 14px;
  color: #fff !important;
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  margin-top: 0;
}

#header.court .main-header .login-option a.get-start-btn {
  background-color: inherit !important;
  border-radius: 5px;
  border-color: #fff;
  font-size: 13px;
  padding: 12px 28px;
  display: inline-block;
}

#header.court .main-nav .slicknav_nav {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 12px;
}

#header.court .main-nav .slicknav_nav a {
  border-width: 0 0 1px 0;
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  font-weight: 500;
  padding: 12px 0;
  margin: 0;
}

#header.court .main-nav .slicknav_nav > li:last-child > a {
  border: 0;
  padding-bottom: 0;
}

#header.court .main-nav .slicknav_nav > li:first-child > a {
  padding-top: 0;
}

#header.court .main-nav .slicknav_nav ul li a {
  border-color: rgba(255, 255, 255, 0.055);
  padding: 10px;
}

#header.court .main-nav .slicknav_nav ul {
  background-color: rgba(0, 0, 0, 0.2);
}

#header.court .main-nav .slicknav_nav ul li:last-child a {
  border: 0;
}

#header.court .main-header .logo {
  padding: 0;
}

#header.court .main-header .login-option {
  margin-left: auto;
}

/*Court Header Style End*/

/* Icon Box Hover Effect Style */

.icon-boxes.fancy .img-holder figure i {
  position: relative;
  overflow: hidden;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.icon-boxes.fancy .img-holder figure i:before {
  display: block;
}

.icon-boxes.fancy:hover .img-holder figure i:before {
  color: #fff;
  -webkit-animation: toLeftFromRight 0.8s forwards;
  -moz-animation: toLeftFromRight 0.8s forwards;
  animation: toLeftFromRight 0.8s forwards;
}

@-webkit-keyframes toLeftFromRight {
  49% {
    -webkit-transform: translate(-50%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(50%);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toLeftFromRight {
  49% {
    -moz-transform: translate(-50%);
  }
  50% {
    opacity: 0;
    -moz-transform: translate(50%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toLeftFromRight {
  49% {
    -webkit-transform: translate(-50%);
    -moz-transform: translate(-50%);
    transform: translate(-50%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(50%);
    -moz-transform: translate(50%);
    transform: translate(50%);
  }
  51% {
    opacity: 1;
  }
}

/* Icon Box Hover Effect End */

/* Fancy Button Hover Effect */

.cs-calltoaction a {
  -webkit-transform: perspective(1px) translateZ(0);
  -moz-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-property: color;
  -moz-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.cs-calltoaction a:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -moz-transition-property: transform, -moz-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform, -moz-transform;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.cs-calltoaction a:hover:before,
.cs-calltoaction a:focus:before,
.cs-calltoaction a:active:before {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  -moz-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Fancy Button Hover Effect End */

/* Company Logo Hover Effect */

.company-logo.simple ul li figure a img {
  -webkit-transform: perspective(1px) translateZ(0);
  -moz-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -moz-transition-property: transform, -moz-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform, -moz-transform;
}

.company-logo.simple ul li figure a img:hover {
  -webkit-transform: translateY(-5px);
  -moz-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  transform: translateY(-5px);
}

/* Company Logo Hover Effect End */

.widget-recent-blog-post .img-holder figure:before,
.widget-recent-blog-post .img-holder figure:after {
  background-color: rgba(217, 29, 29, 0.4);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  content: "";
  width: 0;
  z-index: 1;
  pointer-events: none;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.widget-recent-blog-post .img-holder figure:after {
  right: 0;
  left: auto;
}

.widget-recent-blog-post .img-holder figure {
  display: inline-block;
  overflow: hidden;
  width: 100%;
}

.widget-recent-blog-post li:hover .img-holder figure:before,
.widget-recent-blog-post li:hover .img-holder figure:after {
  width: 50%;
}

/*Contact Area Style Start*/

.contact-area {
  text-align: center;
  padding-top: 50px;
}

.contact-area span {
  display: inline-block;
  vertical-align: middle;
}

.contact-area a {
  border-radius: 100%;
  font-size: 30px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  height: 58px;
  line-height: 56px;
  margin: 0 15px;
  width: 58px;
}

.contact-area a i {
  display: inline-block;
  vertical-align: middle;
}

.contact-area .time a {
  background-color: rgba(30, 116, 222, 0.5);
  -webkit-box-shadow: 0 0 0 4px rgba(45, 126, 216, 0.2);
  box-shadow: 0 0 0 4px rgba(45, 126, 216, 0.2);
}

.contact-area .phone a {
  background-color: rgba(233, 47, 43, 0.5);
  -webkit-box-shadow: 0 0 0 4px rgba(246, 72, 68, 0.2);
  box-shadow: 0 0 0 4px rgba(246, 72, 68, 0.2);
}

.contact-area .location a {
  background-color: rgba(97, 218, 0, 0.5);
  -webkit-box-shadow: 0 0 0 4px rgba(150, 235, 65, 0.2);
  box-shadow: 0 0 0 4px rgba(150, 235, 65, 0.2);
}

.contact-area .tooltip.top {
  margin-top: -10px;
}

.contact-area .tooltip-inner {
  background-color: rgba(154, 47, 45, 0.5);
  font-size: 13px;
  padding: 5px 10px;
  max-width: inherit;
  width: 100%;
}

.contact-area .tooltip.top .tooltip-arrow {
  border-top-color: rgba(154, 47, 45, 0.5);
  border-width: 10px 10px 0;
  margin-left: -10px;
  bottom: -5px;
}

.contact-area .time .tooltip-inner {
  background-color: rgba(30, 116, 222, 0.5);
}

.contact-area .time .tooltip.top .tooltip-arrow {
  border-top-color: rgba(30, 116, 222, 0.5);
}

.contact-area .location .tooltip-inner {
  background-color: rgba(97, 218, 0, 0.5);
}

.contact-area .location .tooltip.top .tooltip-arrow {
  border-top-color: rgba(97, 218, 0, 0.5);
}

/*Contact Area Style End*/

.main-section:after {
  display: block;
  content: "";
  clear: both;
}

textarea#comment_mes {
  display: none;
}

.foodbakery-dev-payment-form {
  padding: 25px;
  background: #fff;
}

.mr-2 {
  margin-right: 20px;
}

a {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.categories-order a.btn-cross i {
  margin-right: 10px;
  font-size: 14px;
}

.mat-snack-bar-container.success {
  max-width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 1rem !important;
  padding: 10px !important;
  background-color: #39b44d !important;
  color: #fff;
  top: 30%;
  font-size: 16px;
}

.mat-snack-bar-container.danger {
  max-width: 90% !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 1rem !important;
  padding: 10px !important;
  background-color: #f44336 !important;
  color: #fff;
  top: 30%;
}

.mat-button-wrapper {
  color: #000 !important;
}

/* .cdk-overlay-container {
    top: 20%
} */

.btn-border-danger {
  background: none;
  color: #b92c28 !important;
  border-color: #b92c28 !important;
  text-transform: uppercase;
  border-radius: 2px;
  padding: 5px 12px 4.3px;
}

.custom_tabs .nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px;
  border: 1px solid #39b44d !important;
  padding: 5px 6px;
  font-weight: 600;
  color: #39b44d;
}
.custom_tabs .nav-tabs > li.active > a,
.custom_tabs .nav-tabs > li.active > a:hover,
.custom_tabs .nav-tabs > li.active > a:focus {
  color: #ffffff !important;
  background-color: #39b44d !important;
  border: 1px solid #dddddd;
  cursor: default;
}

.custom_tabs .nav-tabs {
  border: none;
}
.custom_tabs .tab-pane {
  padding: 10px 0;
}

.form-control-sm {
  padding: 5px 6px !important;
  height: 34px !important;
}

.user-nav-list {
  border: 1px solid #dddddd !important;
  padding: 10px 0;
}
